const comments_text = [
    {
        image: "../images/reviews/11.png",
        alt: "Отзывы о работе вебкам-моделью в Саратове 1",
        text: `Я у Наташи работаю уже более полугода,
        она вытащила меня из очень тяжёлой семейной ситуации,
        сейчас я снимаю однокомнатную квартиру, накопила на первоначальный взнос и
        собираюсь брать ипотеку. Я очень благодарна судьбе за мою встречу с Наташей и Avocado studio.
        Здесь я не только заработала денег, но и взглянула на жизнь под другим углом: сейчас мы учимся
         любви к себе, защите границ и основам женского пикапа.
         Желаю директорам и Наташе работать долго и
         так же продуктивно. Спасибо Вам ещё раз 🙏`,
        name: "Катя"
    },
    {
        image: "../images/reviews/22.png",
        alt: "Отзывы о работе вебкам-моделью в Саратове 2",
        text: `Работаю в этой компании уже более 3х лет и, думаю,
        время работы говорит уже само за себя 😀 комфортные условия работы, справедливое отношение и,
        конечно же, помощь и обучение для роста в заработках - это необходимые элементы для достижения успеха,
        которые вам здесь обеспечены.
        Искренне благодарна своим менеджерам Наде и Вике за поддержку и результативную работу
        на протяжении всего этого времени. 😍`,
        name: "Софа"
    },
    {
        image: "../images/reviews/33.png",
        alt: "Отзывы о работе вебкам-моделью в Саратове 3",
        text: `Мне все нравится, Эль. Правда. Не знаю даже, к чему придраться. Ну хотелось
        бы поспать с утра побольше, но я же понимаю, что это мои деньги и я не имею права просыпать свои деньги.
        Тем более с утра оч хорошо идёт у меня. Просто человеческое спасибо тебе за все: за машину,
        за поездки за границу, за то, что смогла маме помочь. Я обрела здесь вторую семью.
        Ааааа ну и ещё, попроси Катю не есть мою еду 😂`,
        name: "Таня"
    },
    {
        image: "../images/reviews/44.png",
        alt: "Отзывы о работе вебкам-моделью в Саратове 4",
        text: `Добрый вечер. Нет, не поздно! Все хорошо, все поняла. Я уже сказала Вике,
        что готова работать и мы составили расписание. Большое спасибо Вам. Студия очень уютная,
        я была приятно удивлена, да и девчонки у Вас работают такие приветливые . Конечно, стеснялась, но Вика прям расположила к себе.
        Плюс круто добираться до работы, много автобусов едет до Чапаева. Так что надеюсь, у меня получится работать,
        так как деньги очень нужны 😟
        Единственный вопрос, если у меня  практика в марте и придётся уехать на неделю, как можно решить этот вопрос?`,
        name: "Анастасия"
    },
    {
        image: "../images/reviews/55.png",
        alt: "Отзывы о работе вебкам-моделью в Саратове 5",
        text: `Привет , вчера получила зарплату первую, спасибо тебе от души! Ты не просто хороший управляющий,
        ты в первую очередь замечательный человек. В жизни не встречала таких ласковых и заботливых руководителей.
        Порой жизнь поступает с нами нечестно и происходит много плохого и ты права, главное - верить и что-то делать,
        поднимать себя с постели и действовать. Теперь я не грущу, теперь я знаю, что сама в ответе за свою жизнь.
        И хорошо, что до этого не попала на другие студии, вряд ли бы я там осталась, такой подход, наверное, только у вас.
        Спасибо за честность и, как ты говоришь, за «неоказание медвежьей услуги». Работаем дальше 🙌`,
        name: "Тамара"
    },
    {
        image: "../images/reviews/77.png",
        alt: "Отзывы о работе вебкам-моделью в Саратове 6",
        text: `Привееееет! Слушай , все супер! Я короче научилась фишкам😉, теперь я точно стану богатым Буратино 😅😅😅 П
        опроси Вику мне дать побольше часов!
        А то она жалеет меня И очень извиняюсь за вазу! Но вообще она сама упала 😀
        Когда ты к нам заедешь на студию ? Я уже соскучилась!`,
        name: "Ленчик"
    },
    {
        image: "../images/reviews/11.png",
        alt: "Отзывы о работе вебкам-моделью в Саратове 7",
        text: `Работаю в данной компании уже на протяжении 3-х лет! И легко могу сказать, что это
        именно то место о котором можно мечтать! Если ты хочешь изменить себя и свою жизнь,
        то тебе нужно именно сюда! Ты получишь тут моральную поддержку нашего отзывчивого и справедливого менеджера 24/7,
        который поможет тебе в любой ситуации! Так же тебе помогут раскрыть твои лучшие качества, твою женскую энергию, ум,
        красоту и конечно же ты будешь получать очень хорошую зарплату! Хороший, добрый, веселый коллектив +
        новые и интересные знакомства, это круто!
        Можно много говорить о всех преимуществах нашей компании, но лучше всего попробовать это самой!`,
        name: "Оля"
    },
    {
        image: "../images/reviews/22.png",
        alt: "Отзывы о работе вебкам-моделью в Саратове 8",
        text: `В начале когда появилась возможность попробовать себя в этой сфере я отнеслась скептически (зная что и как устроено).
        Но когда я переступила порог и меня встретила очень приветливая улыбка, позитивный настрой моего менеджера,
         сомнения начали отступать. Спасибо всей команде за помощь даже в самых сложных ситуациях (не только рабочая часть)!
         Менеджеры готовы помочь в любое время суток, особое спасибо нашему тех сотруднику (Егор, прости за глупые вопросы, особенно в начале).
         И такая красивая, позитивная атмосфера! Самая красивая, уютная студия которую я вообще видела. Коллектив очень дружный.
          Как бы тяжело не было знакомиться с людьми, каждая девочка очень приветлива и открыта к знакомству. Еще очень нравится честность, прямо очень!
        Будь то менеджер или кто-то из коллектива, всегда скажут свое честное слово.
        Всегда есть чай и печеньки, самое главное!!`,
        name: "Кэт"
    },
    {
        image: "../images/reviews/33.png",
        alt: "Отзывы о работе вебкам-моделью в Саратове 9",
        text: `
        Наконец-то появилась возможность рассказать свою историю 😅 Я пришла работать на студию уже больше 4х лет назад. 19-летняя девочка,
        жила в общежитии, которая понятия не имела что это за работа, пришла на собеседование!
        И вот по сей день, я работаю здесь и очень счастлива! Спасибо моему менеджеру Наташе и всему руководству за помощь, поддержку
        и безопасность! Спасибо за то что я живу в своей квартире, за то что езжу на свой машине, отдыхаю за границей и помогаю родителям!
        Я живу жизнью, о которой даже мечтать не могла)
        На протяжении всей моей работы на студии, я получала не только материальное обогащение , но и моральное! Студия-мой второй дом!
        Люди, с которыми я общаюсь на сайтах-мои лучшие друзья! Коллектив- вторая семья! Это замечательная атмосфера,
        очень прибыльное и крутое место) p.a.очень скоро добьюсь ещё несколько целей и пойду во взрослую жизнь, сильной, обеспеченной и уверенной в себе девушкой))`,
        name: "Алина"
    },
    {
        image: "../images/reviews/44.png",
        alt: "Отзывы о работе вебкам-моделью в Саратове 10",
        text: `Хочу поделится  опытом работы на данной студии. Атмосфера приятная, дружный коллектив, приятная  обстановка и никакого обмана.
        Мой «начальник», а именно менеджер - Виктория, замечательный человек , который не просто помогает в работе, мотивирует своими достижениями, но так же оказывает  психологическую поддержку даже в личных ситуациях. 👍
        Лично мне удаётся совмещать  работу и с учёбой и с хобби, так как график работы мы выбираем сами, главное не опаздывать!`,
        name: "Анюта"
    },
]

export default comments_text