import { useState } from "react";
import { Link } from "react-router-dom";
import InputMask from 'react-input-mask';
import axios from 'axios';
import { Button } from '../Button'
import classNames from 'classnames'
import './FormContacts.scss'
import { ModalWindow } from "../ModalWindow/ModalWindow";
import ym from 'react-yandex-metrika';
const token = process.env.REACT_APP_TOKEN;
const chat = process.env.REACT_APP_CHAT_ID;
const api = process.env.REACT_APP_API;

export const FormContacts = ({ classBlock, state, ym_click }) => {
  const [nameValue, setName] = useState({ name: '', style: "normal" })
  const [phoneValue, setPhone] = useState({ phone: '', style: "normal" })
  const [messageValue, setMessage] = useState({ message: '', style: "normal" })
  const [checkbox, setCheckbox] = useState({ checkbox: false, style: "normal", checked: '' })
  const [time, setTime] = useState({ time: '' })
  let checkboxElem = document.getElementById('orderform-is_old');
  let checkboxElemFooter = document.getElementById('old_footer');

  function getName(e) {
    let target = e.target;
    nameValue.name = target.value;
    setName({ ...nameValue })
  }

  function getPhone(e) {
    let target = e.target;
    phoneValue.phone = target.value;
    setPhone({ ...phoneValue })
  }

  function getMessage(e) {
    let target = e.target;
    messageValue.message = target.value;
    setMessage({ ...messageValue })
  }

  function getCheckbox(e) {
    let target = e.target;
    checkbox.checkbox = target.checked;

    if (target.checked) {
      checkbox.checked = "checked"
      // target.setAttribute("checked", "checked")
    } else {
      checkbox.checked = ""
      // target.removeAttribute("checked");
    }

    setCheckbox({ ...checkbox })
  }

  function isEmpty(str) {
    if (str.trim() == '') return true;
    return false;
  }

  function isLetters(str) {
    const rex = /[^A-Za-zА-Яа-яЁё]+/ig;
    let trimStr = str.trim();
    let arrValue = trimStr.match(rex);


    if (arrValue === null) {
      nameValue.style = "normal";
      setName({ ...nameValue })
      return true
    }
    if (arrValue.length > 0 || arrValue === 'undefined') {
      nameValue.style = "error";
      setName({ ...nameValue })
      return false
    }
  }

  function isPhone(number) {

    // console.log("number: ", number)
    try {
      let regexp = /\d/g;
      let num = number.match(regexp).join('');

      if (num.length == 11) {
        phoneValue.style = "normal";
        setPhone({ ...phoneValue })
        return true
      } else {
        phoneValue.style = "error";
        setPhone({ ...phoneValue })
        return false
      }
    } catch (e) {
      console.log(e)
    }

  }


  function isCheckbox(value) {

    // console.log("isCheckbox: ", value)

    if (value) {
      checkbox.style = "normal";
      setCheckbox({ ...checkbox })
      return true
    } else {
      checkbox.style = "error";
      setCheckbox({ ...checkbox })
      return false
    }
  }

  async function send(e) {
    e.preventDefault()

    // let windowInnerHeight = window.innerHeight;
    // stateHeight.height = windowInnerHeight;
    // setwindowInnerHeight({ ...stateHeight });


    if (isEmpty(nameValue.name)) {
      nameValue.style = "error";
      setName({ ...nameValue })
      return false
    } else if (isEmpty(phoneValue.phone)) {
      phoneValue.style = "error";
      setPhone({ ...phoneValue })
      return false

    }

    else {
      let name = isLetters(nameValue.name)
      let phone = isPhone(phoneValue.phone)
      let checkBox = isCheckbox(checkbox.checkbox)



      if (name && phone && checkBox) {
        // console.log("checkBox: ", name && phone && checkBox)
        // document.body.style.overflow = "hidden";
        // setFlag(true);

        const data = {
          subject: "Email Avocado",
          title: "Письмо с сайта форма обратной связи",
          name: nameValue.name,
          phone: phoneValue.phone,
          message: messageValue.message,
          time: time.time
        }

        let res = await sendData(data);
        console.log("res: ", res)

        if (res[0] === 200 || res[1] === 200) {

          if (checkboxElem !== undefined) {
            // console.log('checkboxElem: ', checkboxElem)
            checkboxElem.removeAttribute("checked");
            checkboxElemFooter.removeAttribute("checked");
          }

          nameValue.name = '';
          setName({ ...nameValue });
          phoneValue.phone = '';
          setPhone({ ...phoneValue })

          messageValue.message = '';
          setMessage({ ...messageValue })
          checkbox.checked = ""
          setCheckbox({ ...checkbox })
          state(true)
        } else {
          // setFlag(false)
          // modalWindow.flag = true;
          // modalWindow.text = 'Что-то пошло не так, попробуйте отправить сообщение позже';
          // setModalWindow({ ...modalWindow })
          // if (!modalWindow.flag) document.body.style.overflow = "visible";
        }
      }
    }
  }

  async function sendData(data) {

    let message = `<b>Avocado</b>\nИмя: ${data.name}\nТелефон: ${data.phone}\n`;
    message += data.time ? `<b>Время для звонка: </b> ${data.time}` : '';

    let stateTelegram = null;

    try {
      // let telegram = await axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
      // chat_id: chat,
      axios.post(`https://api.telegram.org/bot7026747272:AAEoJQk4n09n2fSOXzcGGmhIdQv2WWq4vMA/sendMessage`, {
        // axios.post(`https://api.telegram.org/bot7090874198:AAFxlNkrmgl-8tpGOAXuFwH0nzTxfiuSEaI/sendMessage`, {
        chat_id: '-1002144853368',

        // chat_id: '-1001993172345',
        parse_mode: 'html',
        text: message
      })
        .then(function (response) {

          stateTelegram = response.status;
        });


      // console.log("data: ", data)
      let respons = await fetch(`https://evolsar.com/api/formcontact_avocado`, {
        // let respons = await fetch(`https://evolsar.com/api/formcontact_avocado`, {
        method: 'POST',
        body: JSON.stringify(data),
      });

      let res = await respons;
      let status = res.status;



      return [stateTelegram, status]
      // console.log('telegram: ', stateTelegram, " status: ", status)
    } catch (e) {
      console.log("try: ", e)
    }
  }

  return (
    <div className={classBlock}>
      <form id="order_form" className="">
        <fieldset className="data">
          <div className="box">
            <input type="text" id="orderform-name1" onChange={(e) => getName(e)} value={nameValue.name} className={classNames("name", nameValue.style)} name="name" required placeholder="Твоё имя" aria-required="true" />
            <p className=""></p>
          </div>


          <div className="box">
            <InputMask name="phone" value={phoneValue.phone} onChange={getPhone} className={classNames("phone", phoneValue.style)} placeholder="Номер телефона" mask="+7 (999) 999-99-99" />
            {/* <input type="text" id="orderform-phone2" className="" name="phone" required placeholder="Номер телефона" data-inputmask="&#039;mask&#039;: &#039;+7-999-999-99-99&#039;" aria-required="true" /> */}
            <p className=""></p>
          </div>

        </fieldset>

        <fieldset>
          <div className="text_box">
            <textarea id="orderform-message" onChange={getMessage} value={messageValue.message} className="message" name="message" rows="7" placeholder="Твой вопрос или комментарии (не обязательно)"></textarea>
            <p className=""></p>
          </div>
        </fieldset>

        <fieldset className='time_call'>
          <label className="title_select" htmlFor="time">Когда перезвонить?</label>
          <select id="time" name="time" onChange={(e) => setTime({ ...time, time: e.target.value })}>
            <option value="звонить с 9 до 12">звонить с 9 до 12</option>
            <option value="звонить с 12 до 16">звонить с 12 до 16</option>
            <option value="звонить с 16 до 21">звонить с 16 до 21</option>
          </select>
        </fieldset>

        <fieldset className="confirmation">
          <div className="checkbox">

            {classBlock == 'additional' ?
              <label className='info_label' htmlFor="old_footer">

                <input type="checkbox" id="old_footer" name="is_old" onChange={getCheckbox} checked={checkbox.checked} required />
                <span>Мне исполнилось 18 лет.</span>
              </label>
              :
              <label className='info_label_contact' htmlFor="orderform-is_old">

                <input type="checkbox" id="orderform-is_old" name="is_old" onChange={getCheckbox} checked={checkbox.checked} required />
                <span>Мне исполнилось 18 лет.</span> <span className="apply">Я даю согласие на обработку персональных данных в соответствии с
                  <Link to={"/policy"} className="policy-href"> Политикой конфиденциальности.</Link>
                </span>
              </label>
            }

            <span className={classNames("checkbox_message", checkbox.style)} >Установите галочку для разрешения обратки ваших персональных данных</span>
          </div>
          <p className="help-block help-block-error form-validation"></p>
        </fieldset>


        <div className="btn_submit_form">
          <Button>
            <button type="submit" onClick={(e) => {
              try{
                send(e); 
                ym(96368984, 'reachGoal', `${ym_click}`); 
              } catch(e){
                console.log("Error: ",  e)
              }
            }
               } 
               className='theme_button'>отправить</button>
          </Button>
        </div>


        <div className="old">
          <span className="warning_text">18+</span>
        </div>
      </form>
    </div>
  )
}