import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp, faTelegram, faVk } from '@fortawesome/free-brands-svg-icons';
import './Header.scss'
import '../Button/Button.scss'
import { Button } from '../Button';
import { useEffect, useRef, useState } from 'react';

export const Header = ({ logo, getHeight }) => {

    const [state, setState] = useState(false)
    const [menu, setStateMenu] = useState({ state: false })

    const ref = useRef(null)

    useEffect(() => {

        window.addEventListener("resize", function () {

            try {
                let height = ref.current.getBoundingClientRect().height;
                getHeight(height)
                // console.log("resize, ", height)
            } catch (e) {
                // console.log(e)
            }
        });

        if (document.readyState === "complete") {
            let height = ref.current.getBoundingClientRect().height;
            getHeight(height)
            // console.log("load, ", height)
        }

        window.addEventListener("scroll", function () {
            if (window.scrollY > 30) {
                setState(true)

            } else {
                setState(false)
            }
        });

    })

    function scrollToTop() {
        window.scrollTo(0, 0);
    }

    function closeMenu() {
        if (menu.state) {
            menu.state = false;
            setStateMenu({ ...menu })
        } else {
            menu.state = true;
            setStateMenu({ ...menu })
        }

    }
    // http://shpargalkablog.ru/2012/04/position-fixed-html.html
    return (
        <div ref={ref} className='wrapper_header'>
            <div className='page_topline' data-active={state}>
                <div className='container_fluid'>
                    <div className='social_media'>

                        <a href="https://wa.me/79167540213" target="_blank" className="fa fa-whatsapp ">
                            <FontAwesomeIcon icon={faWhatsapp} style={{ color: '#fff' }} />
                        </a>
                        <a href="https://tlgg.ru/@Avocado_studio_sar" target="_blank" className="fa fa-telegram">
                            <FontAwesomeIcon icon={faTelegram} style={{ color: '#fff' }} />
                        </a>
                        <a href="https://vk.com/avocado.webcam" target="_blank">
                            <FontAwesomeIcon icon={faVk} style={{ color: '#fff' }} />
                        </a>

                        {/* <img className='icon' src="./images/icon-telegram.svg" /> */}
                    </div>
                    <div className='contact_info'>
                        <div>
                            <a href="mailto:svetlana.baranova12345@mail.ru">svetlana.baranova12345@mail.ru</a>
                        </div>
                        <div>
                            <a href="tel:+79167540213">+7 916 75 402 13</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='page_header'>
                <header>
                    <div className='container_header'>
                        <div className='content_header'>
                            <div className='logo'>
                                <NavLink onClick={scrollToTop} to="/">
                                    <img src={logo} />
                                </NavLink>
                            </div>
                            <div className='menu'>
                                <div className='mobile_menu_button'>
                                    <button onClick={closeMenu}>&#9776;</button>
                                </div>
                                <nav className='list_menu' data-active={menu.state}>
                                    <div className='close_menu'>
                                        <button onClick={closeMenu}>&#10006;</button>
                                    </div>
                                    <NavLink onClick={scrollToTop} to="/">о студии</NavLink>
                                    <NavLink onClick={scrollToTop} to="/rabota-webcam-modelyu-v-saratove">о работе</NavLink>
                                    <NavLink onClick={scrollToTop} to="/otzyvy-o-rabote-webcam-modelyu-v-saratove">отзывы</NavLink>
                                    <NavLink onClick={scrollToTop} to="/voprosy-i-otvety-o-rabote-webcam-modelyu-v-saratove">вопросы и ответы</NavLink>
                                </nav>
                            </div>
                            <Button>
                                <a href={'#contacts'} className=''>
                                    <button className='theme_button'>Записаться</button>
                                </a>
                            </Button>
                        </div>
                    </div>
                </header>
            </div>
        </div>
    )
}