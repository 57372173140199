const slides = [
    {
        "id": 1,
        "title": "Машина",
        "img": "dream-car.webp",
        "alt": "Вебкам-студия в Саратове калькулятор цель машина",
        "data": {
            "name": "Машина",
            "price": 1200000,
            'month': "1",
        }
    },
    {
        "id": 2,
        "title": "Квартира",
        "img": "dream-flat.webp",
        "alt": "Вебкам-студия в Саратове калькулятор цель машина",
        "data": {
            "name": "Квартира",
            "price": 2200000,
            'month': "1",
        }
    },
    {
        "id": 3,
        "title": "Путешествие",
        "img": "dream-travel.webp",
        "alt": "Вебкам-студия в Саратове калькулятор цель отдых",
        "data": {
            "name": "Отдых",
            "price": 150000,
            'month': "1",
        }
    },
    {
        "id": 4,
        "title": "Телефон",
        "img": "dream-phone.webp",
        "alt": "Вебкам-студия в Саратове калькулятор цель телефон",
        "data": {
            "name": "Телефон",
            "price": 100000,
            'month': "1",
        }
    },
]

export default slides