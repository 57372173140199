import { useState } from "react";
import { Button } from "../Button"
import { FormContacts } from "../FormContacts"
import { ModalWindow } from "../ModalWindow/ModalWindow"
import './AdditionalInfoBlock.scss'

export const AdditionalInfoBlock = () => {
    const [stateModal, setStateModal] = useState(false)

    const modal = setStateModal;

    return (
        <div className="additional_info">
            <div className="container_additional_info">
                <div className="card_additional_info">
                    <div className="widget_theme_wrapper">
                        <div id="text-2" className="widget widget_text">
                            <h3 className="widget_title">ХОЧЕШЬ РАБОТАТЬ?</h3>
                            <div className="widget_image">
                                <img src="../images/answer1.png" alt="Вебкам-студия в Саратове ХОЧЕШЬ РАБОТАТЬ?" />
                            </div>
                            <div className="">
                                <Button>
                                    <a href={'#contacts'} className=''>
                                        <button className='theme_button'>ОСТАВИТЬ ЗАЯВКУ</button>
                                    </a>
                                </Button>
                                {/* <div className="form-wrapper">
                                      <a href="/#contacts" className="theme_button color-orange">ОСТАВИТЬ ЗАЯВКУ</a>
                                  </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card_additional_info">
                    <div className="widget_theme_wrapper">
                        <div id="qhub_bloginfo-2" className="widget widget_bloginfo">
                            <h3 className="widget_title">ХОЧЕШЬ ПОГОВОРИТЬ?</h3>
                            <div className="widget_image">
                                <img src="../images/answer2.png" alt="Вебкам-студия в Саратове ХОЧЕШЬ ПОГОВОРИТЬ?" />
                            </div>
                            <FormContacts classBlock={"additional"} state={modal} ym_click={'submitting_form'} />
                            <div id="wrapper_media">
                                <div style={{ padding: "5px" }}>
                                    <a href="https://webcam-studios.ru">
                                        <img src="https://webcam-studios.ru/wp-content/uploads/2023/01/webcam-studios.gif" width="220px" />
                                    </a>
                                </div>
                            </div>
                            {/* <div className="">
                                <div className="form-wrapper">
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="card_additional_info">
                    <div className="widget_theme_wrapper">
                        <div id="qhub_popular-3" className="widget widget_popular_entries">
                            <h3 className="widget_title">ОСТАЛИСЬ ВОПРОСЫ?</h3>
                            <div className="widget_image">
                                <img src="../images/answer3.png" alt="Вебкам-студия в Саратове ОСТАЛИСЬ ВОПРОСЫ?" />
                            </div>
                            <div className="">
                                <Button text={'Подробнее'} link={'#'} />
                                {/* <div className="form-wrapper">
                                      <a href="/voprosy-i-otvety-o-rabote-webcam-modelyu-v-saratove" className="theme_button color-orange">Подробнее</a>
                                  </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalWindow visible={stateModal}>
                <div className="modal_form_contact">
                    <div className="modal_header">
                        <button className="btn_close_contact" onClick={() => setStateModal(false)}>&times;</button>
                    </div>
                    <div className="modal_body">
                        <p>Благодарим!</p>
                        <p>Твоё сообщение успешно отправлено, <br></br>
                            в ближайшее время мы с тобой свяжемся</p>
                    </div>
                </div>
            </ModalWindow>
        </div>
    )
}