import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { Home, AboutJob, Comments, Questions, Policy } from './pages';
import reportWebVitals from './reportWebVitals';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename='/'>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/rabota-webcam-modelyu-v-saratove" element={<AboutJob />} />
      <Route path="/otzyvy-o-rabote-webcam-modelyu-v-saratove" element={<Comments />} />
      <Route path="/voprosy-i-otvety-o-rabote-webcam-modelyu-v-saratove" element={<Questions />} />
      <Route path="/policy" element={<Policy />} />
      {/* <Route path="*" element={<Error />} /> */}
    </Routes>
  </BrowserRouter>
);

reportWebVitals();
