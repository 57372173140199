import './Breadcrumbs.scss'

export const Breadcrumbs = ({breadcrumbs, current}) => {

    return (
        <div className="breadcrumbs">
            <div className="container_title_page">
                <h1 className="title_page">
                    {breadcrumbs}
                </h1>
                <ul className="breadcrumb">
                    <li className="first_item">
                        <a href="/">Главная / </a>
                    </li>
                    <li className="last_item">{ current }</li>
                </ul>
            </div>
        </div>
    )
}