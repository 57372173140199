import { useState } from 'react'
import axios from 'axios';
import InputMask from 'react-input-mask'
import psyhotest from '../../data/psyhotest'
import { Button } from '../Button'
import './PsyhoTest.scss'
const token = process.env.REACT_APP_TOKEN;
const chat = process.env.REACT_APP_CHAT_ID;
const api = process.env.REACT_APP_API;

const calc = {
    "stateQustion": true,
    "stateAnswer": false
}

const answers = [];
const textAnswers = { answers: {} }
let targetPrice = null;

export const PsyhoTest = () => {
    const [state, setState] = useState(1)
    const [text, setStateText] = useState(true)
    const [stateCal, setStateCal] = useState(calc)
    const [stateSlide, setstateSlide] = useState({ value: 1 })
    const [stateTarget, setTarget] = useState({ id: "", price: null, state: false })
    const [salary, setSalary] = useState({ summa: null, mouth: null })

    function changeQuestion(e) {
        let target = e.target;

        try {
            // console.log("changeQuestion target: ", e.target)

            // "title: ", target.dataset.title, " value:", target.dataset.value,

            if (state == 1) {

                if (target.getAttribute('class') !== 'items_image' || target.getAttribute('class') !== 'item_text') {
                    if (target.getAttribute('class') == 'item_image') {

                        // console.log("element: ", target)
                    }
                    if (target.getAttribute('class') == 'image') {
                        let parent = target.parentElement;

                        if (parent.getAttribute('class') == 'item_image') {

                            target = parent;
                            // console.log("parent: ", parent)
                        }
                    }
                }

                if (target.dataset.coefficient != undefined) {
                    answers.push(Number(target.dataset.coefficient));
                    textAnswers.answers[target.dataset.title] = target.dataset.value;
                }

                setState(state + 1)

            } else if (state == 6) {
                if (target.dataset.value == 'Да') {
                    setState(state + 1)
                } else {
                    setState(state + 3)
                }

            } else if (state == 8) {
                let input = document.getElementById("workpPlace");

                if (input.dataset.coefficient != undefined) {
                    answers.push(Number(input.dataset.coefficient));
                    textAnswers.answers[input.dataset.title] = input.value;
                }
                setState(state + 1)
            } else {
                if (target.dataset.coefficient != undefined) {
                    answers.push(Number(target.dataset.coefficient));
                    textAnswers.answers[target.dataset.title] = target.dataset.value;
                }
                setState(state + 1)
            }
        } catch (e) {
            console.log(e)
        }
    }

    async function sendFormTest(e) {
        e.preventDefault();
        let res = await sendData(textAnswers);
        setStateCal({ ...stateCal, stateQustion: false });
    }

    async function sendData(data) {
        let message = `<b>Email Avocado</b>\n<b>Письмо с сайта тест</b>\nТелефон: ${data.phone}\nВыбранные варианты:\n`;

        Object.entries(data.answers).map(item => {
            message += `<b>${item[0]}</b>  -  ${item[1]}\n`
        })

        axios.post(`https://api.telegram.org/bot7026747272:AAEoJQk4n09n2fSOXzcGGmhIdQv2WWq4vMA/sendMessage`, {
            // axios.post(`https://api.telegram.org/bot7090874198:AAFxlNkrmgl-8tpGOAXuFwH0nzTxfiuSEaI/sendMessage`, {
            chat_id: '-1002144853368',
            parse_mode: 'html',
            text: message
        })
        // .then(function (response) {
        //     console.log(response.data)
        // });


        data["subject"] = "Email Avocado";
        data["title"] = "Письмо с сайта тест";
        // console.log(data)

        try {
            let respons = await fetch(`https://evolsar.com/api/formcontact_avocado`, {
                method: 'POST',
                body: JSON.stringify(data),
            });

            let res = await respons;
            let status = res.status;

            return status
        } catch (e) {
            console.log(e)
        }

    }

    function resultTest() {
        let res = null;
        let answer = '';
        for (let i = 0; i < answers.length; i++) {
            res += answers[i]
        }

        if (res <= 12) return <p className='result_test'>Спасибо, что ответила на все наши вопросы! К сожалению, работа вебкам-моделью не то, что тебе нужно. Но возможно твои ответы были поспешными и результат получился с погрешностью. В любом случае, мы ждем тебя в студии AVOCADO!</p>;
        if (res > 12 && res < 21) return <p className='result_test'>Спасибо, за ответы! Ты довольно уверенная личность) и можешь попробовать себя в роли вебкам-модели! С радостью примем тебя в здоровую семью AVOCADO!</p>
        if (res >= 21) return <p className='result_test'>Спасибо, за ответы! Ты довольно уверенная личность) и можешь попробовать себя в роли вебкам-модели! С радостью примем тебя в здоровую семью AVOCADO!</p>;
    }

    function getData() {
        let q = psyhotest.find(item => item.id == state)

        if (q == undefined) {

            if (stateCal.stateQustion) {
                return (
                    <>
                        <p className='test_form_text'>Напиши свой номер телефона (мы не будем звонить тебе прямо сейчас, просто хотим убедиться, что ты не робот):)</p>
                        <div className='content_form_test_phone'>
                            <form onSubmit={(e) => { e.preventDefault(); }}>
                                {/* <input type="text" className='' placeholder="Ваше имя" onChange={(e) => getName(e)} /> */}
                                <InputMask className="form_test_phone" onChange={(e) => { textAnswers['phone'] = e.target.value; }} placeholder="Номер телефона" mask="+7 (999) 999-99-99" />
                                <Button>
                                    <button type="submit" onClick={(e) => sendFormTest(e)}>Далее</button>
                                </Button>
                            </form>
                        </div>
                    </>
                )
            } else {
                return (
                    <>
                        {resultTest()}
                        <div className='container_button'>
                            <Button>
                                <a href={'#contacts'} className=''>
                                    <button className='theme_button'>заполнить анкету</button>
                                </a>
                            </Button>
                        </div>
                    </>
                )
            }

        } else if (q.id == 1) {

            return (
                <div className='content'>
                    <div className=''>
                        <div className='title_psyhotest'
                            dangerouslySetInnerHTML={{ __html: q.title }}
                        />
                    </div>
                    <div className='items_image'>
                        {
                            q.text.map((i, index) =>
                                <div className='item_image'
                                    key={index} data-title={q.title}
                                    data-value={i.title}
                                    data-coefficient={i.coefficient}
                                    onClick={(e) => { changeQuestion(e); setStateText(false) }} >
                                    <img className='image' src={i.image} alt={i.title} />
                                    <h4 style={{ pointerEvents: "none" }}>{i.title}</h4>
                                    <p className='description'>{i.value}</p>
                                </div>

                                // <div className='option' key={index} data-coefficient={i.coefficient} onClick={(e) => changeQuestion(e)}>{i.value}</div>

                            )
                        }

                    </div>
                </div>
            )

        } else if (q.id == 8) {

            // data-title="Место работы";
            // data-value={e.value};

            return (
                <div className='content'>
                    <div className=''>
                        <div className='title_psyhotest'
                            dangerouslySetInnerHTML={{ __html: q.title }}
                        />
                    </div>
                    <div className='content_form_work'>
                        <div className='form_work'>
                            <input id="workpPlace" className='workpPlace' data-title={"Место работы"} data-coefficient={0} type="text" maxLength="100" placeholder="Место работы и должность до 100 символов" />
                            <br></br>
                            <button className='btn_form_work' onClick={(e) => changeQuestion(e)} >Дальше</button>
                        </div>
                    </div>
                </div>
            )

        } else {
            {

                return (
                    <div className='content'>
                        <div className=''>
                            <div className='title_psyhotest'
                                dangerouslySetInnerHTML={{ __html: q.title }}
                            />
                        </div>
                        <div className='items_text'>
                            {
                                q.text.map((i, index) =>
                                    <div className='item_text' key={index} >
                                        <p className='description_text' data-title={q.title} data-value={i.value} data-coefficient={i.coefficient} onClick={(e) => changeQuestion(e)}>{i.value}</p>
                                    </div>

                                    // <div className='option' key={index} data-coefficient={i.coefficient} onClick={(e) => changeQuestion(e)}>{i.value}</div>

                                )
                            }

                        </div>
                    </div>
                )

            }
        }
    }


    return (
        <div className='container_psyhotest' id="online-test">
            <p className="test-info" data-active={text}>
                Кто может стать вебкам-моделью? Многие считают, что такая профессия подходит каждой девушке.
                На самом деле, работа вебкам-моделью это не просто развлечение, это настоящий труд!
                Не стоит относиться к этой работе, как просто к общению на сайте знакомств.
                А чтобы узнать, сможешь ли ты стать успешной в этой сфере, ответь на несколько простых вопросов:
            </p>
            {getData()}
        </div>
    )
}
