// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../font/Roboto-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../font/Roboto-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../font/CenturyGothic.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Roboto-Regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
@font-face {
  font-family: "Roboto-Light";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}
@font-face {
  font-family: "Century Gothic";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}
.policy .policy_rules .container_policy {
  margin: 0 auto;
  max-width: 1200px;
}
.policy .policy_rules .container_policy h4 {
  color: rgb(60, 60, 60);
  font-family: "Century Gothic";
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 3rem;
}
.policy .policy_rules .container_policy p {
  color: rgb(129, 129, 129);
  font-family: "Roboto-Light";
  font-size: 17px;
  line-height: 1.48em;
}
.policy .policy_rules .container_policy p strong {
  font-weight: 700;
}
.policy .policy_rules .container_policy p a {
  text-decoration: none;
  color: rgb(40, 71, 34);
}`, "",{"version":3,"sources":["webpack://./styles/fonts.scss","webpack://./src/pages/Policy/Policy.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,+DAAA;ACCJ;ADEA;EACI,2BAAA;EACA,+DAAA;ACAJ;ADGA;EACI,6BAAA;EACA,+DAAA;ACDJ;AAPQ;EACI,cAAA;EACA,iBAAA;AASZ;AAPY;EACI,sBAAA;EACA,6BAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;AAShB;AANY;EACI,yBAAA;EACA,2BAAA;EACA,eAAA;EACA,mBAAA;AAQhB;AANgB;EACI,gBAAA;AAQpB;AALgB;EACI,qBAAA;EACA,sBAAA;AAOpB","sourcesContent":["@font-face {\n    font-family: \"Roboto-Regular\"; \n    src: url(\"../font/Roboto-Regular.ttf\") format(\"truetype\");  \n}\n\n@font-face {\n    font-family: \"Roboto-Light\"; \n    src: url(\"../font/Roboto-Light.ttf\") format(\"truetype\");  \n}\n\n@font-face {\n    font-family: \"Century Gothic\"; \n    src: url(\"../font/CenturyGothic.ttf\") format(\"truetype\");  \n}\n\n","@import '../../../styles/fonts.scss';\n\n.policy {\n    .policy_rules {\n        .container_policy {\n            margin: 0 auto;\n            max-width: 1200px;\n\n            h4 {\n                color: rgb(60, 60, 60);\n                font-family: \"Century Gothic\";\n                font-size: 1.8rem;\n                font-weight: 600;\n                line-height: 3rem;\n            }\n\n            p {\n                color: rgb(129, 129, 129); \n                font-family: \"Roboto-Light\";\n                font-size: 17px;\n                line-height: 1.48em;\n\n                strong {\n                    font-weight: 700;\n                }\n\n                a {\n                    text-decoration: none;\n                    color: rgb(40, 71, 34);;\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
