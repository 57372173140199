import { Zoom } from 'react-awesome-reveal';
import classNames from 'classnames';
import './CardsInfo.scss'

export const CardsInfo = ({ cards, classContainer, class_card }) => {

    function renderCards() {
        const render = cards.map((item, index) => <Zoom key={index} top triggerOnce={true} duration={400} delay={400} fraction={0} >
            <div  className={classNames('icon_bonus', class_card)}>
                <div className='image_bonus'>
                    <img src={item.image} alt={item.alt} />
                </div>
                <h3 className={`title_${class_card}`}>{item.title}</h3>
                <div className='description'>{item.text}</div>
            </div>
        </Zoom>)

        return render;
    }
    return (
        <div className='bonuses'>
            <div className={classNames('container_cards', classContainer)}>

                {renderCards()}
            </div>
        </div>
    )
}