// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer .container_footer {
  color: rgb(129, 129, 129);
}
.footer .row {
  display: flex;
  flex-direction: row;
  padding: 30px 0;
}
@media screen and (max-width: 480px) {
  .footer .row {
    flex-direction: column;
  }
}
.footer .row .copy {
  padding: 0 10%;
  text-align: left;
  width: 50%;
}
@media screen and (max-width: 480px) {
  .footer .row .copy {
    text-align: center;
    width: 100%;
    padding: 0 0 10px 0;
  }
}
.footer .row .social_icons {
  padding: 0 10%;
  width: 50%;
  text-align: right;
}
.footer .row .social_icons a {
  margin: 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;AAAR;AAGI;EACI,aAAA;EACA,mBAAA;EACA,eAAA;AADR;AAGQ;EALJ;IAMQ,sBAAA;EAAV;AACF;AAEQ;EACI,cAAA;EACA,gBAAA;EACA,UAAA;AAAZ;AAEY;EALJ;IAMQ,kBAAA;IACA,WAAA;IACA,mBAAA;EACd;AACF;AAEQ;EACI,cAAA;EACA,UAAA;EAEA,iBAAA;AADZ;AAGY;EACI,cAAA;AADhB","sourcesContent":[".footer {\n    .container_footer {\n        color: rgb(129, 129, 129);\n    }\n\n    .row {\n        display: flex;\n        flex-direction: row;\n        padding: 30px 0;\n\n        @media screen and (max-width: 480px) {\n            flex-direction: column;\n        }\n\n        .copy {\n            padding: 0 10%;\n            text-align: left;\n            width: 50%;\n\n            @media screen and (max-width: 480px) {\n                text-align: center;\n                width: 100%;\n                padding:  0 0 10px 0;\n            }\n        }\n\n        .social_icons {\n            padding: 0 10%;\n            width: 50%;\n            // border: 3px solid red;\n            text-align: right;\n\n            a {\n                margin: 0 10px;\n            }\n        }\n    }\n} \n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
