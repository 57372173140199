import { lazy, useEffect, useState } from 'react';
import './App.scss';
import { Footer } from '../Footer';
import { ModalWindow } from '../ModalWindow';
import { Header } from '../Header';

export const App = ({ logo, children }) => {

  const [state, setState] = useState(null)
  const [statePopup, setPopUp] = useState(false)

  useEffect(() => {
    let main = document.getElementById("main");
    main.style.marginTop = Math.round(state) + 'px';

    // console.log("state, ", state, " main: ", main)
  })

  // sessionStorage.setItem('popup', 1);
  // console.log(sessionStorage.getItem('popup'))

  if (sessionStorage.getItem('popup') === null) {
    setTimeout(() => {
      setPopUp(true)
      sessionStorage.setItem('popup', 1);
    }, 8000)
  }

  return (
    <div className="App">
      <Header logo={logo} getHeight={setState} />
      <main id="main">
        {children}
      </main>
      <Footer />
      <ModalWindow visible={statePopup}>
        <div className="modal_popup">
          <button type="button" className="close" onClick={() => setPopUp(false)} data-dismiss="modal">&times;</button>
          <a href="/#calculator" className="popup-btn-close" onClick={() => setPopUp(false)}>
            <img src="/images/pop-up.webp" className='pop-up-adw-img' id="pop-up-adw-img" alt="Узнай! Cколько ты с нами заработаешь" />
          </a>
        </div>
      </ModalWindow>
    </div>
  );
}
