import { Zoom } from 'react-awesome-reveal';
import { Button } from "../Button"
import homeComentsCarusele from '../../data/comments'
import './CommentsBlock.scss'

export const CommentsBlock = () => {
    return (
        <section className='section_comments'>
            <Zoom top triggerOnce={true} duration={400} delay={400} fraction={0}>
                <div className='comments'>
                    <div>
                        <h3 className="comments_titles">Отзывы <strong>моделей</strong></h3>
                    </div>
                    <p className="comments_text">
                        Узнай больше здесь! Хочешь работать на вебкам-студии, но есть сомнения.
                        Отзывы помогут тебе определиться и сделать правильный выбор.
                        Читай откровения наших вебкам-моделей, они поделились своим опытом и рассказали про тонкости работы в вебкам:
                    </p>

                    <div className='comments_carousel'>
                        <img src="../images/reviews/otziv2-min.jpg" alt="" />
                        <img src="../images/reviews/otziv5-min.jpg" alt="" />
                        <img src="../images/reviews/otziv3-min.jpg" alt="" />
                        {/* <div className="content_items_comments_carousel">
                        {
                            homeComentsCarusele.map((item, index) => <div key={index} className="content_item">
                                <div className="text_image">{item.text}</div>
                                <img src={item.img} alt={item.alt} />
                            </div>
                            )
                        }
                    </div> */}
                    </div>
                    <Button>
                        <a href={'otzyvy-o-rabote-webcam-modelyu-v-saratove'} className=''>
                            <button className='theme_button'>все отзывы</button>
                        </a>
                    </Button>
                </div>
            </Zoom>
        </section>
    )
}