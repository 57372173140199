const questions = [
    {
        "id": "1",
        "question": "Сколько часов в день планируешь работать?",
        "title": `<h5>Сколько <span style="color: #91b71c;">часов в день</span> планируешь работать?</h5>`,
        "text": [
            {
                value: "3 часа",
                coefficient: 3
            },
            {
                value: "5 часов",
                coefficient: 5
            },
            {
                value: "8 часов",
                coefficient: 8
            }
        ]

    },
    {
        "id": "2",
        "question": "Сколько дней в неделю?",
        "title": `<h5>Сколько <span style="color: #91b71c;">дней</span> в неделю?</h5>`,
        "text": [
            {
                value: "3 дня",
                coefficient: 3
            },
            {
                value: "5 дней",
                coefficient: 5
            },
            {
                value: "7 дней",
                coefficient: 7
            }
        ]
    },
    {
        "id": "3",
        "question": "Твой уровень английского?",
        "title": `<h5>Твой уровень <span style="color: #91b71c;">английского</span>?</h5>`,
        "text": [
            {
                value: "нет",
                coefficient: 1
            },
            {
                value: "средний уровень",
                coefficient: 1.25
            },
            {
                value: "свободно общаюсь",
                coefficient: 1.5
            }
        ]

    },
    {
        "id": "4",
        "question": "Считаешь себя привлекательной?",
        "title": `<h5>Считаешь себя <span style="color: #91b71c;">привлекательной</span>?</h5>`,
        "text": [
            {
                value: "нет",
                coefficient: 1
            },
            {
                value: "да",
                coefficient: 1.3
            }
        ]

    },
    {
        "id": "5",
        "question": "Считаешь себя особенной?",
        "title": `<h5>Считаешь себя <span style="color: #91b71c;">особенной</span>?</h5>`,
        "text": [
            {
                value: "нет",
                coefficient: 1
            },
            {
                value: "да",
                coefficient: 1.3
            }
        ]

    }
]

export default questions