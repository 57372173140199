import React, { useState, useEffect } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { Zoom } from 'react-awesome-reveal';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AdditionalInfoBlock, App, Breadcrumbs, Button, CommentsBlock, ContactsBlock, Header, PsyhoTest } from '../../components'
import { CardsInfo } from '../../components/CardsInfo/CardsInfo'
import './AboutJob.scss'


export const AboutJob = () => {

    const [stateHeight, setStateHeight] = useState(null)

    const settings = {
        dots: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    const cardsAboutJobPage = [
        {
            image: "../images/student.png",
            alt: "Работа вебкам-моделью в Саратове Хочешь жить в столице?",
            title: "Хочешь стать финансово независимой?",
            text: "Ты можешь работать вебкам-моделью, ведь это довольно прибыльная вакансия. При этом продолжай учиться, заниматься любимым хобби и просто проводить вечера с друзьями."
        },
        {
            image: "../images/want-money.png",
            alt: "Работа вебкам-моделью в Саратове Мечтаешь закочить крутой ВУЗ?",
            title: "Тебе нужна подработка?",
            text: "Работа вебкам-моделью не займет у тебя много времени. И ты получишь дополнительный доход к основному заработку или к стипендии. А еще заведешь себе друзей с разных уголков планеты."
        },
        {
            image: "../images/second_job.png",
            alt: "Работа вебкам-моделью в Саратове Купить квартиру в Питере?",
            title: "Не можешь найти работу без опыта?",
            text: "Работа на вебкам-студии не требует образования или ранее приобретенных умений. Всему можно научиться, главное - это желание учиться, стремление заработать и стать успешной."
        },
    ]

    useEffect(() => {
        document.title = "Работа без опыта - Вебкам-моделью в Саратове - Работа в No Nude";
    })

    return (
        <App logo={'./images/logo/logo2.jpg'}>
            <div className="container_about_job">
                <section>
                    <Breadcrumbs breadcrumbs={'О работе вебкам-моделью в Саратове'} current={'РАБОТА БЕЗ ОПЫТА В Саратове'} />
                </section>


                <section id="whatIsWebcam">
                    <div className="about_webcam_job">
                        <Zoom top triggerOnce={true} duration={400} delay={400} fraction={0}>
                            <div className="animation_block">
                                <div className='wraper_slider'>
                                    <Slider {...settings}>
                                        <div>
                                            <NavHashLink
                                                to="/#calculator">
                                                <img className='slide_1' src="images/what-is-webcam-1.jpg" alt="Работа вебкам-моделью в Саратове модель 1" />
                                            </NavHashLink>
                                        </div>
                                        <div>
                                        <NavHashLink
                                                to="#online-test">
                                               <img className='slide_2' src="images/what-is-webcam-2.jpg" alt="Работа вебкам-моделью в Саратове модель 1" />
                                            </NavHashLink>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </Zoom>
                        <Zoom top triggerOnce={true} duration={400} delay={400} fraction={0}>
                            <div className="descriptionJob">
                                <div className="special_text">
                                    <h3 className=""> <strong>Что такое вебкам?</strong></h3>
                                </div>
                                <p>
                                    Вебкам - сфера бизнеса, которая основана на общении вебкам-модели с наблюдателем через видео-чат.
                                    Занимает от 4х до 6ч в день и приносит достаточно высокий доход.
                                    Работать вебкам-моделью может любая совершеннолетняя девушка, здесь не важен опыт или образование.
                                    Главное быть интересной и уметь находить общий язык с собеседником.
                                </p>
                                <h4 className="item-title thin text-transform-none">Что такое вебкам-студия?</h4>
                                <p>
                                    Это офис, где есть все необходимое для работы вебкам-модели.
                                    Начиная с техники, освещения, красивого интерьера и заканчивая обучением,
                                    регистрацией, организацией рабочего процесса, и продвижением моделей.
                                </p>
                                <h4 className="item-title thin text-transform-none">В чём заключается работа?</h4>
                                <p>
                                    Многие ошибочно считают, что работа вебкам-модели заключается в том, чтобы красиво раздеваться на камеру.
                                    Но это не так. И мы тебе это докажем. В нашей студии модели умеют работать в категориях NO nude (без обнажения).
                                </p>
                                <h4 className="item-title thin text-transform-none">От чего зависит доход?</h4>
                                <p>
                                    Основной фактор это время, которое провела вебкам-модель в онлайн-чате.
                                    Также на заработок влияет знание языка, опыт работы, опрятный внешний вид, общительность, качество трансляции.
                                </p>
                            </div>
                        </Zoom>
                    </div>
                </section>
                <section>
                    <CardsInfo cards={cardsAboutJobPage} classContainer={'about_job'} class_card={'odd'} />
                </section>
                <section>
                    <div className='container_who_need'>
                        <div className="container_text_who_need">
                            <h3>
                                <strong>Кто</strong> нам нужен?
                            </h3>
                            <p> Мы работаем в вебкам с 2008г, знаем все тонкости и подводные камни. Умеем справляться с любыми трудностями и нацелены на честность,
                                открытость и законность в этой сфере. Наша вебкам-студия AVOCADO рада принять в свою команду единомышленников.
                            </p>
                            <p>
                                А для того чтобы работа вебкам-моделью приносила радость, тебе просто необходимо побороть страх, лень и неуверенность в себе!
                                Остальное мы берем на себя.
                                Avocado это команда профессионалов и просто дружная семья.
                                Добро пожаловать!
                            </p>
                        </div>

                        <div className='items_who_need'>
                            <div className="item_who_need">
                                <img src="images/obshitelnost.png" alt="Работа вебкам-моделью в Саратове. Совершеннолетие" />
                                <h3 className="item-title h5">Общительность, готовность учиться</h3>
                            </div>
                            <div className="item_who_need">
                                <img src="images/age.png" alt="Работа вебкам-моделью в Саратове. Общительность и обаятельность" />
                                <h3 className="item-title h5">Совершеннолетие</h3>
                                <div className="teaser_content">
                                    Возраст от 18 до 35 лет.
                                </div>
                            </div>
                            <div className="item_who_need">
                                <img src="images/disciplina.png" alt="Работа вебкам-моделью в Саратове. Дисциплинированность" />
                                <h3 className="item-title h5">Ответственность и дисциплинированность</h3>
                            </div>
                        </div>
                        <div className="container_button">
                            <Button>
                                <a href={'#contacts'} className=''>
                                    <button className='theme_button'>Отправить заявку</button>
                                </a>
                            </Button>
                        </div>
                    </div>
                </section>

                <section className='psyhotest'>
                    <div className='wrapper_psyhotest'>
                        <div className="special_heading">
                            <h2 className="item-title h3 text-uppercase title">Тест</h2>
                        </div>
                        <PsyhoTest />
                    </div>
                </section>

                <section>
                    <div className='about_useful'>
                        <div className="about_useful_title">
                            <h3 className="">Avocado -  <strong>полезная вебкам-студия</strong></h3>
                            <p>
                                Для тех, кто хочет выглядеть на все 100 и НЕ экономить, хочет зарабатывать достаточно и при этом НЕ переступать личные границы.
                            </p>
                            <p> Мы докажем, что работа вебкам-моделью, может быть не такой, как о ней пишут.  Наше направление - прозрачный вебкам.</p>
                        </div>

                        <div className='content_about_usrful'>
                            <div className='about_job_image_usrful'>
                                <img src="/images/our-pluses.jpg" alt="Работа вебкам-моделью в Саратове Лучшая возможность для твоего города" />
                            </div>
                            <div className='list_reasons'>
                                <div className='item_reason'>
                                    <div className='num'>
                                        <span>01</span>
                                    </div>
                                    <div className='item_reason_text'><strong>Зароботная плата</strong> выдается для новеньких два раза в месяц, для постоянных сотрудников раз в месяц.</div>
                                </div>
                                <div className='item_reason'>
                                    <div className='num'>
                                        <span>02</span>
                                    </div>
                                    <div className='item_reason_text'><strong> Комфортная атмосфера.</strong>
                                        Утром ты можешь насладиться ароматным кофе в лаунж-зоне с панорамными окнами,
                                        а вечером расслабиться в хамам или джакузи, легко сделаешь профессиональный
                                        макияж в нашей makeup зоне и почувствуешь себя королевой.</div>
                                </div>
                                <div className='item_reason'>
                                    <div className='num'>
                                        <span>03</span>
                                    </div>
                                    <div className='item_reason_text'><strong>Индивидуальный подход</strong> к каждой вебкам-модели, работа над трансформацией, как личностей в самую лучшую сторону, работа в категории no-nude.</div>
                                </div>
                                <div className='item_reason'>
                                    <div className='num'>
                                        <span>04</span>
                                    </div>
                                    <div className='item_reason_text'><strong>Обучение</strong>  для начинающих вебкам-моделей проходит в отдельной студии.
                                        Здесь царит более офисная атмосфера. Мы проводим вебинары, изучаем правила успешного общения в чате, основные моменты безопасности и тонкости женского пикапа.</div>
                                </div>

                                <Button text={'подробнее о студии'} link={'/#contacts'} />
                            </div>
                        </div>
                    </div>
                </section>
                <CommentsBlock />
                <section>
                    <ContactsBlock />
                </section>
            </div>
        </App>
    )
}
