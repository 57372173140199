import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import slidesFoto from '../../data/slides'
import './SliderFoto.scss'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


export const SliderFoto = () => {

    return (

        <div>
            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                {
                    slidesFoto.map((item, index) =><SwiperSlide key={index}>
                        <img src={`../images/studio/${item.img}`} alt={item.alt} />
                    </SwiperSlide> )
                }
            </Swiper>
        </div >
    )
}