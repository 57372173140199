import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { App, Breadcrumbs, Header } from "../../components"
import './Policy.scss'


export const Policy = () => {

    const [stateHeight, setStateHeight] = useState(null)

    useEffect(()=> {
        document.title = "Политика конфиденциальности";
      })

    return (
        <App logo={'./images/logo/logo1.jpg'}>
            <div className="policy">
                <section>
                    <Breadcrumbs breadcrumbs={'Политика конфиденциальности'} current={'Политика конфиденциальности'} />
                </section>
                <section id="policy_rules" className="policy_rules">
                    <div className="container_policy">
                        <h4>1. Общее положение</h4>
                        <p>
                            Настоящие Положение о политике конфиденциальности (далее — Положение) является официальным документом <strong>
                                AVOCADO</strong> (далее — «Компания"/ «Оператор»),
                            и определяет порядок обработки и защиты информации о физических лицах (далее — Пользователи), пользующихся сервисами,
                            информацией, услугами, программами и продуктами, расположенными на доменном имени https://avocado-models.ru/ (далее — Сайт).
                            Мы разработали Политику Конфиденциальности, которая описывает, как мы осуществляем обработку персональных данных —
                            любые действия (операции) или совокупность действий (операций), совершаемых с использованием средств автоматизации или
                            без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
                            извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
                            Отношения, связанные с обработкой персональных данных и информации о пользователях Сайта, регулируются настоящим Положением, иными официальными документами
                            Оператора и действующим законодательством РФ.
                            Настоящая Политика распространяется на обработку личных, персональных данных, собранных любыми средствами, как активными, так и пассивными, как через Интернет,
                            так и без его использования, от лиц, находящихся в любой точке мира.
                        </p>
                        <h4>2. Сбор персональных данных</h4>
                        <p>
                            Целью обработки персональных данных является выполнения обязательств Оператора перед Пользователями в отношении использования Сайта и его сервисов.
                            Обработка персональных данных пользователей осуществляется с согласия субъекта персональных данных на обработку его персональных данных.
                            Под персональными данными понимается любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу
                            (субъекту персональных данных) и которая может быть использована для идентификации определенного лица либо связи с ним.
                            Мы можем запросить у Вас персональные данные в любой момент, когда Вы связываетесь с Компанией. Компания может использовать такие данные
                            в соответствии с настоящей Политикой Конфиденциальности. Она также может совмещать такую информацию с иной информацией для целей предоставления и
                            улучшения своих продуктов, услуг, информационного наполнения (контента) и коммуникаций.
                            Ниже приведены некоторые примеры типов персональных данных, которые Компания может собирать, и как мы можем использовать такую информацию.
                            Мы можем собирать различные данные/информацию, включая:
                            имя;
                            номер телефона;
                            адрес электронной почты;
                            Персональные данные могут также включать в себя дополнительно предоставляемые Пользователями по запросу Оператора в целях исполнения Оператором обязательств
                            перед Пользователями, вытекающих из договора на оказание услуг. Оператор вправе, в частности, запросить у Пользователя копию документа, удостоверяющего личность,
                            либо иного документа, содержащего имя, фамилию, фотографию Пользователя, а также иные дополнительные данные, которые, по усмотрению Оператора, будут являться необходимыми
                            и достаточными для идентификации такого Пользователя и позволят исключить злоупотребления и нарушения прав третьих лиц.
                        </p>
                        <h4>3. Хранение и использование персональных данных</h4>
                        <p>
                            Персональные данные Пользователей хранятся исключительно на электронных носителях и обрабатываются с использованием автоматизированных систем, за исключением случаев,
                            когда неавтоматизированная обработка персональных данных необходима в связи с исполнением требований законодательства.
                        </p>
                        <h4>4. Передача персональных данных</h4>
                        <p>
                            Персональные данные Пользователей не передаются каким-либо третьим лицам, за исключением случаев, прямо предусмотренных настоящими Правилами.
                            Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах
                            персональных данных с использованием средств автоматизации или без использования таких средств.
                            Пользователь соглашается с тем, что Оператор вправе передавать персональные данные третьим лицам, в частности, курьерским службам, организациям
                            почтовой связи, операторам электросвязи и т. д., исключительно для целей, указанных в разделе «Сбор персональных данных» настоящей Политики конфиденциальности.
                            При указании пользователя или при наличии согласия пользователя возможна передача персональных данных Пользователя третьим лицам-контрагентам Оператора с условием
                            принятия такими контрагентами обязательств по обеспечению конфиденциальности полученной информации, в частности, при использовании приложений.
                            Персональные данные Пользователя могут быть переданы по запросам уполномоченных органов государственной власти РФ только по основаниям и в порядке, установленным законодательством РФ.
                            Оператор осуществляет блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо
                            уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.
                        </p>
                        <h4>5. Уничтожение персональных данных</h4>
                        <p>
                            Персональные данные пользователя уничтожаются при:
                            — при отзыве субъектом персональных данных согласия на обработку персональных данных.
                        </p>
                        <h4>6. Защита персональных данных</h4>
                        <p>
                            Компания предпринимает меры предосторожности — включая правовые, организационные, административные, технические и физические — для обеспечения
                            защиты Ваших персональных данных в соответствии со ст. 19 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных» в целях обеспечения
                            защиты персональных данных Пользователя от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, распространения,
                            а также от иных неправомерных действий третьих лиц.
                        </p>

                        <p>
                            <strong>Целостность и сохранение персональной информации.</strong>
                            Взаимодействуя с Компанией, Вы можете легко поддерживать свои персональные данные и информацию в актуальном состоянии. Мы будем хранить Ваши персональные данные и информацию в течение срока, необходимого для выполнения целей, описываемых в настоящей Политике Конфиденциальности, за исключением случаев, когда более длительный период хранения данных и информации необходим в соответствии с законодательством либо разрешён им.
                            Соблюдение вашей конфиденциальности на уровне компании
                            Для того чтобы убедиться, что Ваши персональные данные находятся в безопасности, мы доводим нормы соблюдения конфиденциальности и безопасности до работников Компании и строго следим за исполнением мер соблюдения конфиденциальности внутри Компании.
                        </p>
                        <p>
                            <strong>Вопросы относительно конфиденциальности.</strong>
                            Если у вас возникнут вопросы в отношении Политики Конфиденциальности Компании или обработки данных Компанией,
                            Вы можете связаться с нами через
                            {/* <a href="/#contacts" class="count">форму обратной связи.</a> */}
                            <Link to="/" hash="#contacts" > форму обратной связи.</Link>
                        </p>
                        <h4>7. Обращения пользователей</h4>
                        <p>
                            К настоящей Политике Конфиденциальности и отношениям между Пользователем и Оператором применяется действующее законодательство РФ.
                            Пользователи вправе направлять Оператору свои запросы, в том числе запросы относительно использования их персональных данных, направления отзыва согласия на обработку персональных данных в письменной форме по адресу, указанному разделе 1 настоящего положения, или в форме электронного документа, подписанного квалифицированной электронной подписью в соответствии с законодательством РФ, и отправленного по средствам формы обратной связи.
                            Оператор обязуется рассмотреть и направить ответ на поступивший запрос Пользователя в течение 30 дней с момента поступления обращения.
                        </p>
                        <h4>8. Прочие условия</h4>
                        <p>
                            Во всем остальном, что не отражено напрямую в Политике Конфиденциальности, Компания обязуется руководствоваться нормами и положениями Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных». Посетитель сайта Компании, предоставляющий свои персональные данные и информацию, тем самым соглашается с положениями данной Политики Конфиденциальности.
                        </p>


                    </div>
                </section>
            </div>
        </App>
    )
}