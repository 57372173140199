import ym from 'react-yandex-metrika';
import './Button.scss'

export const Button = ({children }) => {

    // function func(e) {

    //     try {
    //         if(ym_func !== '') {
    //             ym(96368984,'reachGoal', ym_func); 
    //         } else {
    //             console.log("ym_func: ", ym_func, " e: ", e)
    //         }
    //     } catch(e) {}

    // }
    return (
        <div className='control_link'>
            {children}
            {/* <a href={link} className=''>
                    <button type={type} onClick={(e)=> {click(e); console.log("click send"); ym(96368984,'reachGoal', `${ym_func}`); }} className='theme_button'>{text}</button>
            </a> */}
        </div>
    )
}