const psyhotest = [
    {
        "id": "1",
        "title": "Выбери свой тип темперамента:",
        "text": [
            {
                image:"../images/1-sangvinik.png",
                title: "Сангвиник",
                value: "Оптимист, балагур, весельчак. В романтических отношениях не постоянна. Азартна и самоуверенна.",
                coefficient: 1.3
            },
            {
                image:"../images/2-melanholik.png",
                title: "Меланхолик",
                value: "Перфекционист - довольно чувственная натура. Не любишь хаос, в твоей жизни преобладает порядок во всем.",
                coefficient: 2.16
            },
            {
                image:"../images/3-holerik.png",
                title: "Холерик",
                value: "Индивидуалист - всегда имеешь свое мнение. Ужасно нетерпеливая, иногда агрессивна, прямолинейна.",
                coefficient: 3.03
            },
            {
                image:"../images/4-flegmatik.png",
                title: "Флегматик",
                value: "Ленивый ворчун - равнодушна к чужим эмоциям. Невозмутима в любой ситуации, сдержана в проявлении эмоций.",
                coefficient: 3.03
            }
        ]

    },
    {
        "id": "2",
        "title": "Любишь общение? Можешь говорить часами или разговор для тебя это пытка?",
        "text": [
            {
                value: "Я не могу жить без общения и новых впечатлений. Поэтому мне совершенно не сложно найти общий язык с незнакомцем. Готова сделать первый шаг и завязать разговор.",
                coefficient: 4
            },
            {
                value: "Я люблю живое общение, тяжело молчать целый день. Навыки общения требуются нам каждый день, ведь мы живем в окружении близких, друзей, коллег, соседей и просто людей.",
                coefficient: 3
            },
            {
                value: "Поняла, что люблю общаться с новыми людьми. Некоторые боятся, а мне нравится. В новых знакомствах много плюсов)",
                coefficient: 2
            },
            {
                value: "Я не люблю общаться. Нет страха, или застенчивости. Просто мне не интересны 80% людей. Люблю только своих друзей, но общаюсь с ними не чаще одного раза в неделю.",
                coefficient: 1
            }
        ]
    },
    {
        "id": "3",
        "title": "Любишь выступать на сцене? В школе была звездой или отсиживалась, как зритель?",
        "text": [
            {
                value: "Конечно была звездой. Без меня не проходил ни один праздник. Люблю внимание, поэтому стараюсь всегда выглядеть ярко и эффектно.",
                coefficient: 4
            },
            {
                value: "Я люблю выступать на сцене, и считаю это своим хобби. Вместо страха получаю прилив адреналина, и мне это нравится.",
                coefficient: 3
            },
            {
                value: "95% людей бояться выступать перед зрителями. Я отношусь к этому проценту людей. Но пару раз мне все таки пришлось выйти на сцену и поучаствовать в массовке.",
                coefficient: 2
            },
            {
                value: "У меня страх сцены и публичных выступлений. В домашней обстановке, среди друзей, могу спеть любимую песню в караоке, если буду в веселом настроении)",
                coefficient: 1
            }
        ]

    },
    {
        "id": "4",
        "title": "Как у тебя с памятью? Легко запоминаешь информацию, номера телефонов, дни рождения?",
        "text": [
            {
                value: "Не жалуюсь! Легко запоминаю дни рождения близких и друзей, не ставлю напоминалок. Если на улице познакомлюсь с симпатичным парнем, а телефон мой будет разряжен)) легко запомню его номерок и вечерком созвонюсь).",
                coefficient: 4
            },
            {
                value: "Запоминаю при необходимости. Пока мне не нужна информация, смысла запоминать ее не вижу. Зачем засорять и загромождать свой мозг.",
                coefficient: 3
            },
            {
                value: "Могу непроизвольно запомнить сцену из фильма, если она меня зацепила. Запоминаю всякую ерунду, а важную информацию запомнить трудно.",
                coefficient: 2
            },
            {
                value: "В одно ухо влетело, в другое вылетело” - это поговорка про меня. Мне с большим трудом удается запоминать прочитанную информацию.",
                coefficient: 1
            }
        ]
    },
    {
        "id": "5",
        "title": "Уровень английским языка?",
        "text": [
            {
                value: "Advanced! Ну, вы поняли  продвинутый. Свободно могу выразить свою мысль на английском и поддержать разговор с иностранцами.",
                coefficient: 4
            },
            {
                value: "Люблю посмотреть вечерком любимый сериал в оригинальной озвучке.  А вот общаться сложно, не хватает практики и словарного запаса.",
                coefficient: 3
            },
            {
                value: "Могу понять английскую речь, если мне скажут что то элементарное - Hello! , how are you? what are you doing? Ответить будет трудно, но постараюсь напрячь свои извилины.",
                coefficient: 2
            },
            {
                value: "Из английских слов, знаю только - OPEN и CLOSE, так как часто вижу на дверях магазинов. А так только с переводчиком, смогу перевести, то что мне нужно.",
                coefficient: 1
            }
        ]

    },
    {
        "id": "6",
        "title": "Ты уже работала где-то (компания, должность не имеют значения)?",
        "text": [
            {
                value: "Да",
                coefficient: 2
            },
            {
                value: "Нет",
                coefficient: 1
            },
        ]

    },
    {
        "id": "7",
        "title": "Если ты уже работала, то как долго на одном месте?",
        "text": [
            {
                value: "Больше года",
                coefficient: 4
            },
            {
                value: "Год",
                coefficient: 3
            },
            {
                value: "Меньше года",
                coefficient: 2
            },
            {
                value: "Не работала",
                coefficient: 1
            }
        ]

    },
    {
        "id": "8",
        "title": "Кратко напиши, где и кем ты работала раньше (по желанию):",
    },
    {
        "id": "9",
        "title": "Тебе нравится твое тело (честно)?",
        "text": [
            {
                value: "Каждое утро смотрю на себя в зеркало и мне нравится то, что я вижу. Да, я люблю свое тело! И я достаточно уверенная в себе!",
                coefficient: 4
            },
            {
                value: "У всех есть свои недостатки и я не исключение. Не смотря на, то что я привлекательная, мне есть над чем работать, и есть что хотелось бы изменить.",
                coefficient: 3
            },
            {
                value: "Чтобы мне нравилось мое тело, я регулярно хожу в спорт зал. На диетах не сижу, но поддерживаю себя в тонусе и стараюсь придерживаться здорового питания. Но скажу честно, это не всегда получается)",
                coefficient: 2
            },
            {
                value: "Да, мне не мешало бы похудеть. Но я не гонюсь за идеалом, любите меня такой какая я есть.",
                coefficient: 1
            }
        ]

    },
    {
        "id": "10",
        "title": "Любишь фотографироваться",
        "text": [
            {
                value: "Да! Обожаю!!! Мне кажется без этого просто не возможно жить, вся моя жизнь в сторис и постах)",
                coefficient: 4
            },
            {
                value: "Мне нравиться фотографироваться. Люблю рассматривать свои фото, и любоваться. Правда выбрать удачное фото на аватар бывает сложно)) ведь я на всех красотка))",
                coefficient: 3
            },
            {
                value: "Я больше люблю фотографировать сама, чем себя) Себя же могу доверить только профессиональному фотографу.",
                coefficient: 2
            },
            {
                value: "Не люблю себя на фото, не потому что считаю некрасивой. Просто между моим представлением о себе и тем, что я вижу, есть заметная разница.",
                coefficient: 1
            }
        ]
    },



    {
        "id": "11",
        "title": "Тебя можно назвать целеустремленным человеком?",
        "text": [
            {
                value: "Да, у меня есть цель! И я буду к ней идти не взирая на препятствия, пока не получу, то что хотела.",
                coefficient: 4
            },
            {
                value: "Если я решу похудеть, то все! Я буду каждый день пропадать в спортзале и высчитывать калории, пока не добьюсь желаемой цифры на весах.",
                coefficient: 3
            },
            {
                value: "Как сказать?! Цели есть, но не всегда есть мотивация. Поэтому стараюсь себя дополнительно мотивировать - развешиваю по квартире картинки, с изображением желаемого.",
                coefficient: 2
            },
            {
                value: "Думаю нет, мне трудно сосредоточиться и поставить себе цель. Думаю, это пустая затея. Если это мое, оно и  так ко мне придет. Зачем напрягаться?!",
                coefficient: 1
            }
        ]
    },
    {
        "id": "12",
        "title": "Сколько времени ты готова посвящать, чтобы работать с удовольствием и получать высокий доход?",
        "text": [
            {
                value: "2-3 часа",
                coefficient: 1
            },
            {
                value: "5 часов",
                coefficient: 2
            },
            {
                value: "8 часов",
                coefficient: 3
            },
            {
                value: "Столько сколько нужно, для высокого заработка.",
                coefficient: 4
            }
        ]
    }
]

export default psyhotest