import './ModalWindow.scss'

export const ModalWindow = ({ children, visible }) => {

    // console.log("Modal visible: ", visible)

    if(visible) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "visible";
    } 
    return (
        <div className="modal_window" data-visible={visible}>
                {children}  
        </div>
    )
}