const comentsCarusele = [
{
    img: "../images/reviews/otziv1-min.jpg",
    alt: "Вебкам-студия в Саратове отзыв 1",
},
{
    img: "/images/reviews/otziv2-min.jpg",
    alt: "Вебкам-студия в Саратове отзыв 2",
},
{
    img: "/images/reviews/otziv3-min.jpg",
    alt: "Вебкам-студия в Саратове отзыв 3",
},
{
    img: "/images/reviews/otziv4-min.jpg",
    alt: "Вебкам-студия в Саратове отзыв 4",
},
{
    img: "/images/reviews/otziv5-min.jpg",
    alt: "Вебкам-студия в Саратове отзыв 5",
},
{
    img: "/images/reviews/otziv6-min.jpg",
    alt: "Вебкам-студия в Саратове отзыв 6",
},
{
    img: "/images/reviews/otziv7-min.jpg",
    alt: "Вебкам-студия в Саратове отзыв 7",
},
{
    img: "/images/reviews/otziv8-min.jpg",
    alt: "Вебкам-студия в Саратове отзыв 8",
},
{
    img: "/images/reviews/otziv9-min.jpg",
    alt: "Вебкам-студия в Саратове отзыв 9",
},
{
    img: "/images/reviews/otziv10-min.jpg",
    alt: "Вебкам-студия в Саратове отзыв 10",
},
]

export default comentsCarusele