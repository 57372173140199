import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp, faTelegram, faVk } from '@fortawesome/free-brands-svg-icons';
import './Footer.scss'
import { AdditionalInfoBlock } from '../AdditionalInfoBlock';

export const Footer = () => {
    return (
        <footer className='footer'>
            <div className='container_footer'>
                <AdditionalInfoBlock />
            </div>
            <div className="row">
                <div className="copy">&copy; 2022 Avocado Studio</div>
                <div className="social_icons">
                    {/* <span > */}

                        <a href="https://wa.me/79167540213" target="_blank" className="fa fa-whatsapp ">
                            <FontAwesomeIcon icon={faWhatsapp} style={{ color: '#818181' }} />
                        </a>
                        <a href="https://tlgg.ru/@Avocado_studio_sar" target="_blank" className="fa fa-telegram">
                            <FontAwesomeIcon icon={faTelegram} style={{ color: '#818181' }} />
                        </a>
                        <a href="https://vk.com/avocado.webcam" target="_blank">
                            <FontAwesomeIcon icon={faVk} style={{ color: '#818181' }} />
                        </a>
                    {/* </span> */}
                </div>
            </div>
        </footer>
    )
}