// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.control_link {
  text-align: center;
}
.control_link .theme_button {
  background-color: #91b61b;
  border: 2px solid #91b61b;
  border-radius: 0;
  color: #fff;
  display: inline-block;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 0.9em;
  font-weight: 500;
  padding: 1.1em 2.5em;
  text-decoration: none !important;
  text-transform: uppercase;
}
.control_link .theme_button:hover {
  background-color: transparent;
  color: #91b61b;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAAI;EACI,yBAAA;EACA,yBAAA;EACA,gBAAA;EACA,WAAA;EACA,qBAAA;EACA,yCAAA;EACA,gBAAA;EACA,gBAAA;EACA,oBAAA;EACA,gCAAA;EACA,yBAAA;AAER;AACI;EACI,6BAAA;EACA,cAAA;AACR","sourcesContent":[".control_link {\n    text-align: center;\n    .theme_button {\n        background-color: #91b61b;\n        border: 2px solid #91b61b;\n        border-radius: 0;\n        color: #fff;\n        display: inline-block;\n        font-family: 'Roboto-Regular', sans-serif;\n        font-size: .9em;\n        font-weight: 500;\n        padding: 1.1em 2.5em;\n        text-decoration: none !important;\n        text-transform: uppercase;\n    }\n\n    .theme_button:hover {\n        background-color: transparent;\n        color: #91b61b;\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
