import { useState } from "react";
import { FormContacts } from "../FormContacts"
import { ModalWindow } from "../ModalWindow/ModalWindow"
import './ContactsBlock.scss'

export const ContactsBlock = () => {
    const [stateModal, setStateModal] = useState(false)

    const modal = setStateModal;

    return (
        <div className='contacts' id="contacts">
            <div className='overlay_light'></div>
            <div className='container_contacnts'>
                <div className="title_contacnts">
                    <h3 className="">
                        Вебкам-студия <span className='span_white'> Avocado </span>твой уголок безопасности, где ты смело можешь начать или продолжить
                        карьеру вебкам-модели. Мы уверены, что перемены невозможны без движения.
                        Заполняй анкету - измени свою жизнь! Ведь твой выбор сегодня, повлияет на твою жизнь завтра.</h3>
                </div>
                <FormContacts classBlock={"container_form"} state={modal}  ym_click={'request_acall'} />
            </div>
            <ModalWindow visible={stateModal}>
                <div className="modal_form_contact">
                    <div className="modal_header">
                        <button className="btn_close_contact" onClick={() => setStateModal(false)}>&times;</button>
                    </div>
                    <div className="modal_body">
                        <p>Благодарим!</p>
                        <p>Твоё сообщение успешно отправлено, <br></br>
                            в ближайшее время мы с тобой свяжемся</p>
                    </div>
                </div>
            </ModalWindow>
        </div>
    )
}