import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { App, Breadcrumbs, ContactsBlock, Header } from "../../components"
import './Comments.scss'
import 'swiper/css';
import 'swiper/css/pagination';

import comments_text from '../../data/coments_text'
import comentsCarusele from '../../data/comments'

export const Comments = () => {

    const[slides, setSlides] = useState(3)

    useEffect(() => {
        document.title = "Честно о работе вебкам-моделью в Саратове - Отзывы о вебкам-студии";

        window.addEventListener("resize", function () {

            try {
                if (window.innerWidth > 991) setSlides(3);
                if (window.innerWidth > 680 && window.innerWidth < 991) setSlides(2);
                if (window.innerWidth < 681) setSlides(1);
            } catch (e) {}
        });

        if (document.readyState === "complete") {
            if (window.innerWidth > 991) setSlides(3);
            if (window.innerWidth > 480 && window.innerWidth < 991) setSlides(2);
            if (window.innerWidth < 480) setSlides(1);
        }

    })

    return (
        <App logo={'./images/logo/logo3.jpg'}>
            <div className='comments_page'>
                <section className="breadcrumbs">
                    <Breadcrumbs breadcrumbs={'Отзывы о работе вебкам-моделью в Саратове в Саратове'} current={'отзывы'} />
                </section>
                <section>
                    <div className='swiper_description'>
                        <p>
                            Еще сомневаешься, стоит ли работать вебкам-моделью? Иногда, чтобы повысить личную эффективность нужно выйти из зоны комфорта.
                            И сделать то, на что долго решалась.
                            Читай отзывы, знакомься с нашим коллективом Avocado.
                            Девчонки откровенно рассказали про нашу студию, условия работы и тонкости в работе вебкам-моделью. Решение за тобой!
                        </p>
                    </div>
                    <div className='container_swiper'>
                        <Swiper
                            slidesPerView={slides}
                            spaceBetween={30}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                        >
                            {
                                comentsCarusele.map((item, index) => <SwiperSlide key={index}>
                                    <img src={item.img} alt={item.alt} />
                                </SwiperSlide>)
                            }

                        </Swiper>
                    </div>
                </section>
                <section>
                    <div className="container_text_comments">
                        {comments_text.map((item, index) => <div key={index} className="review_box">
                            <p className="text_comment">{item.text}</p>
                            <img src={item.image} alt={item.alt} />
                            ``    <p><b className="name">{item.name}</b></p>
                        </div>
                        )}
                    </div>
                </section>
                <section>
                    <ContactsBlock />
                </section>
            </div>
        </App>
    )
}