const questions = [
    {
        question: ` Как работают вебкам-модели?`,
        answer: `<p>Работа происходит в изолированной комнате, где находишься ты, компьютер и веб-камера,
        собеседник находится по ту сторону экрана, общение происходит посредством переписки и напоминает
        общение в социальных сетях, но за каждую минуту общения тебе начисляются деньги. Каждая девушка
        может попробовать себя в роли вебкам-модели, но не у всех получается, наши администраторы сделают все возможное,
        подберут персональные сайты, раскрутят аккаунты, чтобы добиться максимально высоких заработков!</p>`
    },
    {
        question: `Сколько реально зарабатывают вебкам-модели?`,
        answer: `<p>Ответ на вопрос с точными цифрами </p>
        <a href="/#calculator" class="count"><b>Тут</b></a></p>`
    },
    {
        question: `Сколько времени требуется для начала работы?`,
        answer: `<p>Перед началом работы тебя подробно знакомят с сайтами и правилами.
        Если тебя все устраивает, то мы регистрируем в студии в качестве модели.
        Обычно после положительного решения все занимает от 3 до 4 дней,
        в зависимости от твоего свободного времени и желания.</p>`
    },
    {
        question: `Какой график работы и возможно ли совмещение с учебой?`,
        answer: `<p>График удобный, в том плане, что ты сама устанавливаешь свой график,
        когда и сколько тебе работать. Благодаря этому ты можешь учиться или
        совмещать работу с другими занятиями.</p>`
    },
    {
        question: `Это интим?`,
        answer: `<p>Работа в нашей студии исключает интимную связь с посетителями видеочатов.
        Общение происходит только онлайн и анонимно. Можно флиртовать или говорить на любые другие темы,
        быть застенчивой или сексапильной — только тебе решать, как строить общение.
         Это твой индивидуальный стиль работы.</p>`
    },
    {
        question: `Это законно?`,
        answer: `<p>Студия — это предприятие телекоммуникационной (интернет) сферы. Регулирование ее деятельности относится к законам о предпринимательстве,
        а поскольку мы послушно соблюдаем все требования регистрации предпринимательства и налогообложения,
        то чисты перед законом. Публичные оферты на сайтах оговаривают эти аспекты.</p>`
    },
    {
        question: `Для работы обязательно ли знать английский язык?`,
        answer: `<ul> <li>а) Да, ты можешь работать без знания английского языка.
        На рабочих площадках есть встроенные переводчики, которые автоматически переводят все сообщения
        пользователя и также позволяют “на лету” переводить твои сообщения на английский язык для посетителя.
    </li>
    <li>б) Специфика работы подразумевает общение с иностранцами.
Следовательно, чтобы адекватно поддерживать диалог, тебе просто необходимо знать основы английского языка. Однако, совершенно не обязательно владеть языком на профессиональном уровне. Вполне достаточно понимать о чём говорит/пишет твой собеседник и уметь на это ответить.
</li></ul>`
    },
    {
        question: `Что дает работа вебкам-моделью, кроме хорошего заработка?`,
        answer: `<ul><li>Модели становятся более открытыми и начинают верить в себя и свой силы.
        благодаря общению с иностранными посетителями совершенствуются знания английского языка;
        </li>
    <li>Появляется возможность для самосовершенствования – мотивация заняться спортом, сменить имидж,
        обновить гардероб, стать более внимательной к себе;</li>
    <li>Улучшается понимание противоположного пола, человеческой психологии и тонкостей общения;</li>
    <li>За короткий срок можно вернуть уверенность в себе и почувствовать себя женщиной.</li></ul>`
    },
    {
        question: `Бывает ли так, что человеку не удается заработать и почему? С кем это происходит?`,
        answer: `<p>Да, конечно, такое случается. Причины этому: лень, грубое общение с клиентами,
        усталый внешний вид, неумение следить за собой и грамотно себя подать.
        Пройти наш  <a href="/rabota-webcam-modelyu-v-saratove#online-test" class="count"><b>онлайн-тест</b></a> и узнай, на сколько
        работа вебкам-моделью подходит именно тебе.</p>`
    },
    {
        question: `Что дает работа вебкам-моделью, кроме хорошего заработка?`,
        answer: `<p>Странное сравнение.</p>
        <p>В видео-чате человек продает не свое тело, а умение общаться. Конечное, эротика тут присутствует,
        но, по правилам сайтов, ты не обязаны даже раздеваться. Поэтому можешь держать желания клиентов в рамках
        разумного и возможного лично для тебя. К тому же, ты даже не видишь своего клиента, который смотрит на тебя.
        Поэтому, воспринимаешь это не как прямой контакт, а, скорее, как игру. А уж, твоя безопасность и
        здоровье не обсуждаются. И все-таки многие считают, что это плохое занятие.</p>
        <p>Это вполне естественно.</p>
        <p>Успех со стороны серого большинства вызывает зависть и недоброжелательное отношение.
        Пойми главное: работать моделью – не стыдно, стыдно не работать!
        Решай сама, что для тебя важнее – успех или следование традициям бабушек.
        Кстати, ты интересовалась мнением дедушки, как тогда относиться к моделям на подиуме,
        рекламирующих обалденное нижнее белье???</p>`
    },
    {
        question: `Насколько интересна эта работа?`,
        answer: `<p>Работа модели видеочата достаточно необычна для общества, в котором мы с тобой находимся.
        Тщательно рассмотреть возможность своей работы веб-моделью в студии стоит хотя бы потому, что работа модели видеочата сочетает в себе столько привлекательных черт, что далеко не всякая работа сегодня тебе их в состоянии предложить.
        Для работы моделью от тебя не требуется специального образования или глубоких профессиональных навыков – достаточно оставаться самой собой, добавляя немного фантазии и заинтересованности.</p>`
    },
    {
        question: `Я могу работать всего несколько часов в день, стоит ли мне вообще начинать?`,
        answer: `<p>Конечно стоит, тебе обязательно понравится работать у нас.
        Требования к минимальному времени проводимому на сайте моделью в день у нас не существует.
        Но есть рекомендация работы 6-ти часовой смены.
        Время проведенное в онлайне прямо пропорционально заработку.</p>`
    },
    {
        question: `Как ваша студия помогает модели раскрутиться?`,
        answer: `<p>Мы делаем регистрации на всевозможных иностранных порталах, как на широко известных, так и менее известных, но не менее прибыльных. Помогаем тебе раскрутиться на сайтах при помощи специальных программ, делаем индивидуальные страницы, клипы, фотографии.
        В студии работает методика обучения моделей,
        которая поможет тебе научиться правильно работать с сайтами, а также эффективно работать на камеру,
        что сделает тебя более успешной.</p.`
    },
    {
        question: `Нужно ли раздеваться перед клиентом?`,
        answer: `<p>Клиенты платят больше тем девушкам, которые естественны и умеют заинтересовать.
        Не обязательно раздеваться перед клиентом, главное очаровать его и дать то общение, на которое он рассчитывает.</p>`
    },
    {
        question: `Нужно ли встречаться с клиентами и оказывать им интимные услуги?`,
        answer: `<p>Конечно же нет! Все встречи с клиентами носят исключительно виртуальный характер и не выходят за рамки интернет-общения. 
        Мы применяем строгие меры к нарушителям вплоть до отзыва договора о сотрудничестве с моделью.</p>`
    },
    {
        question: `Здесь нет ответа на мой вопрос.`,
        answer: `<p>Если у тебя остались вопросы о вебкаме или нашей студии,
        заполни форму обратной связи на сайте, и менеджер свяжется с тобой.</p>`
    },
]


export default questions