import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { App, Breadcrumbs, ContactsBlock, Header } from "../../components"
import questions from '../../data/questions'
import './Questions.scss'

// https://www.digitalocean.com/community/tutorials/react-font-awesome

const array = [];
(function () {
    for (let i = 0; i < questions.length; i++) {
        if(i == 0 ) {
            questions[i].state = true;
        } else {
            questions[i].state = false;
            questions[i].id = i;
        }
       
        array.push(questions[i])
    }
}())

export const Questions = () => {

    const [state, setState] = useState(array)
    const[stateHeight, setStateHeight] = useState(null)

    useEffect(()=> {
        document.title = "Ответы на часто задаваемые вопросы о работе вебкам-моделью";
      })

    function openItem(e) {

        let target = e.target;
        let id = target.id

        for (let i = 0; i < array.length; i++) {

            if (array[i].id == id && array[i].state == true) {
                array[i].state = false;
                setState({ ...array });
                // return;
            } else if (array[i].id == id) {
                array[i].state = true;
                setState({ ...array });
            } else {
                array[i].state = false;
                setState({ ...array });
            }
        }
    }

    return (
        <App logo={'./images/logo/logo1.jpg'}>
            <div className="questions">
                    <section>
                        <Breadcrumbs breadcrumbs={'Вопросы и ответы о работе вебкам-моделью в Саратове'} current={'Вопросы и ответы'} />

                        <div className="questions_title">
                            <h2 className="">Ответы на часто
                                задаваемые вопросы</h2>
                        </div>

                        <div className="container_questions">
                            {array.map((item, index) => <div
                                key={index} className="container_question"  >
                                {/* <span  className="front"></span> */}
                                {/* <FontAwesomeIcon icon={faPen} /> */}

                                <div className="title_question" id={item.id} data-active={item.state} onClick={(e) => openItem(e)}>
                                    <span>
                                        <FontAwesomeIcon icon={faPen} />
                                        {/* style={{ color: "#323232", }} */}
                                    </span>
                                    <span>{item.question}</span>
                                    <span>{item.state ? "-" : "+"}</span>

                                </div>
                                <div className={item.state ? "answer" : "hidden"}>
                                    <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                                </div>
                            </div>)}
                        </div>
                    </section>

                    <section>
                        <ContactsBlock />
                    </section>
                </div>
        </App>
    )
}
