import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Advantages, BlockIncomeCalculator, Button, SliderFoto, CardsInfo, CommentsBlock, ContactsBlock, AdditionalInfoBlock, Header, App } from "../../components"
import './Home.scss'

const cardsHomePage = [
  {
    image: "../images/main-1.png",
    alt: "",
    title: "Заработок от 1200$",
    text: "Доход наших моделей, как и вечнозеленое дерево авокадо растет быстро и только вверх. И не зависит от размера оклада, премий, и рабочего дня."
  },
  {
    image: "../images/main-2.png",
    alt: "",
    title: "Гибкий график",
    text: "Полностью подстраиваемый под учебу или другой вид деятельности. Выбирай время начала и окончания работы самостоятельно."
  },
  {
    image: "../images/main-3.png",
    alt: "",
    title: "Бонусы",
    text: "Подарки в виде телефонов, планшетов, путешествий в разные уголки мира. Приятные мелочи в виде косметики, а также аксессуаров."
  },
]

export const Home = () => {

  const [stateHeight, setStateHeight] = useState(null)
  const settings = {
    dots: false,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    document.title = "Вебкам-студия AVOCADO в Саратове - Заработок от 1200$ - Гибкий график";
  })

  return (
    <App logo={'./images/logo/logo1.jpg'}>
      <section className='intro_section page_mainslider'>
        <div className='container_slider'>
          <Slider {...settings}>
            <div>
              <img src='../images/slider/slider1-min.webp' />
            </div>
            <div>
              <img src='../images/slider/slider2-min.webp' />
            </div>
          </Slider>
        </div>
      </section>
      <section>
        <CardsInfo cards={cardsHomePage} classContainer={"home"} class_card={'even'} />
      </section>

      <section id='about_job'>
        <div className='container_job'>
          <div className='wrapper_job'>
            <div className='title_job'>
              <h3>О нашей<strong> вебкам-студии</strong> в&nbsp;Саратове</h3>
            </div>
            <div className='content_job'>
              <div className='description_job'>
                <p><strong>Avocado</strong> - это вебкам-студия со строгим NO NUDE
                  контентом и прозрачными условиями. В первую очередь мы хотим развенчать слух о том, что эта работа нелегальная и
                  построена на производстве порно-индустрии. Работа вебкам-моделью может быть разной, в нашей студии девчонки
                  раскрываются в первую очередь как личности, без обнажения и эротик-шоу.</p>
                <p className="item-title h5 thin text-transform-none black base-text">
                  У нас здоровый коллектив, образованные менеджеры со знанием английского языка, красивые комнаты, чистота, честность.
                  Колоссальный опыт, более 10 лет в индустрии.</p>
                <p className="item-title h5 thin text-transform-none black base-text">
                  Мы проводим психологические тренинги,  регулярные фотосессии, бесплатные услуги визажиста и стилиста.
                  С моделями работают менеджеры золотого стандарта руководителя, которые выводят в топ в кратчайшие сроки.</p>
                <p className="item-title h5 thin text-transform-none black base-text">
                  <strong>Avocado</strong> - это фрукт, который занимает особое место по полезности. Так и наша вебкам-студия обладает максимальной полезностью для вебкам-моделей.
                  С нами тебе будет максимально здорово и полезно!
                </p>
                <Button>
                  <a href={'/rabota-webcam-modelyu-v-saratove'} className=''>
                    <button className='theme_button'>подробнее о работе</button>
                  </a>
                </Button>
              </div>
              <div className='image_job'>
                <img src='../images/about-me.webp' />
              </div>
            </div>
          </div>
        </div>

      </section>

      <section id='calculator' className='calculator'>
        <div className='container_calculator'>
          <div>
            <h3><strong>Калькулятор дохода.</strong> Посчитай прямо сейчас, сколько ты заработаешь.</h3>
          </div>
          <BlockIncomeCalculator />
        </div>
      </section>

      <section>
        <Advantages />
      </section>

      <section>
        <div className='foto_studio'>
          <div className='container_foto_studio'>
            <h3> <strong>Фото</strong> студии</h3>
            <p className="description_foto_studio">
              В вебкам-студии Avocado есть все, как для начинающих моделей,
              так и для тех, кто с опытом. У нас тебе точно понравится, давай проведем тебе мини экскурсию и расскажем про обстановку
              в студии. Каждая комната отличается своим интерьером , большинство комнат выполнены
              в уютных пастельных тонах, много зеркал, новая мебель, индивидуальные шкафчики для каждой модели.
            </p>
          </div>
          <SliderFoto />
        </div>
      </section>
      <CommentsBlock />
      <section id="contacts">
        <ContactsBlock />
      </section>
    </App>
  )
} 