const advantages = [
    {
        "id": 1,
        "title": "Соврмененная техника",
        "img": "pre1.png",
        "alt": "Вебкам-студия в Саратове Профессиональное оборудование",
        "text": "Компьютеры с широкоформатными мониторами, HD камеры, дополнительное освещение - софтбоксы, звукоизоляция.",
    },
    {
        "id": 2,
        "title": "Безопасность",
        "img": "pre2.png",
        "alt": "Вебкам-студия в Саратове Бонусы и подарки",
        "text": "Вопрос конфиденциальности строго контролируется администрацией. Никто не знает личных данных вебкам-моделей. Мы проводим инструктаж по безопасности и поведению в видео-чате.",
    },
    {
        "id": 3,
        "title": "Процент и выплаты",
        "img": "pre3.png",
        "alt": "Вебкам-студия в Саратове Безопасность",
        "text": "50%-60% от основного заработка получают модели. Зарплату не задерживаем и выдаем четко по графику. Возможны авансы.",
    },
    {
        "id": 4,
        "title": "Обучение",
        "img": "pre4.png",
        "alt": "Вебкам-студия в Саратове Помощь в переезде",
        "text": "Работа без опыта. Обучаем с нуля - функционал сайтов, азы визажа, тонкости женского пикапа, результативное общение и правила успешного видеочата.",
    },
    {
        "id": 5,
        "title": "Здоровая атмосфера",
        "img": "pre5.png",
        "alt": "Вебкам-студия в Саратове Моя команд",
        "text": "Не зря мы называемся Avocado - в нашей вебкам-студии следят за питанием и здоровьем моделей, но это не значит, что мы гонимся за стандартами 90-60-90. Нет, мы ценим доверие, прозрачность и надежность.",
    },
    {
        "id": 6,
        "title": "Долгосрочное сотрудничество",
        "img": "pre6.png",
        "alt": "Вебкам-студия в Саратове Фотосессии",
        "text": "Официальное трудоустройство. Работаем более 5 лет с 80% наших моделей, у нас ценят каждого. Мы в ответе за тех, кого обучили)",
    }
]

export default advantages