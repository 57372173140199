import { useState } from 'react'
import ym from 'react-yandex-metrika';
import InputMask from 'react-input-mask'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import questions from '../../data/calculator'
import slides from '../../data/slidesCalculator'
import './BlockIncomeCalculator.scss'
import { Button } from '../Button';
import { ModalWindow } from '../ModalWindow/ModalWindow';
const token = process.env.REACT_APP_TOKEN;
const chat = process.env.REACT_APP_CHAT_ID;
const api = process.env.REACT_APP_API;

const calc = {
    "stateQustion": true,
    "stateAnswer": false
}

const answers = [];
const textAnswers = { answers: {} }
let targetPrice = null;

export const BlockIncomeCalculator = () => {

    const [state, setState] = useState(1)
    const [stateCal, setStateCal] = useState(calc)
    const [stateSlide, setstateSlide] = useState({ value: 1 })
    const [stateTarget, setTarget] = useState({ id: "", price: null, state: false })
    const [salary, setSalary] = useState({ summa: null, mouth: null })
    const [modal, setStateModal] = useState({ state: false })

    function changeQuestion(e) {

        if (stateTarget.id !== "") {
            let target = e.target.dataset;
            answers.push(Number(target.coefficient))
            textAnswers.answers[target.question] = target.value;
            setState(state + 1)
            // console.log(target.coefficient, " : ", target.value)
        } else {
            setStateModal({ ...modal, state: true })
        }
    }

    function changeSlide(e) {
        let target = e.target.id;

        if (target == 'prev') {
            stateSlide.value = stateSlide.value - 1;
            if (stateSlide.value >= 1 && stateSlide.value <= 4) setstateSlide({ ...stateSlide });

            if (stateSlide.value < 1) {
                stateSlide.value = 4;
                setstateSlide({ ...stateSlide })
            }
        }

        if (target == 'next') {
            stateSlide.value = stateSlide.value + 1;

            if (stateSlide.value >= 1 && stateSlide.value <= 4) setstateSlide({ ...stateSlide });
            if (stateSlide.value > 4) {
                stateSlide.value = 1;
                setstateSlide({ ...stateSlide })
            }

        }
    }

    function closeModal() {
        modal.state = false;
        setStateModal({ ...modal })
    }

    function getTarget(e) {
        stateTarget.id = +e.target.dataset.id;
        stateTarget.price = e.target.dataset.price;
        stateTarget.state = !false;
        setTarget({ ...stateTarget })
        targetPrice = e.target.dataset.price;
        answers.push(+e.target.dataset.id)
    }

    async function getSalary() {

        try {
            let a = slides.find(item => item.id == answers[0]);
            let val = a.data.price;
            let summa = (answers[1] * 1000 * answers[2] * answers[3] * answers[4] * answers[5] * 4.3);
            let mouth = a.data.price / summa;
            salary.summa = new Intl.NumberFormat("ru-RU").format(Math.round(summa));
            salary.mouth = Math.ceil(mouth);

            setSalary({ ...salary })
            await sendFormCalc(textAnswers);
            // return Math.round(summa);
        } catch (e) {
            console.log("ошибка из калькулятора", e)
        }
    }


    async function sendFormCalc(data) {

        let message = `<b>Email Avocado</b>\n<b>Письмо с сайта калькулятор</b>\nТелефон: ${data.phone}\nВыбранные варианты:\n`;

        Object.entries(data.answers).map(item => {
            message += `${item[0]}  -  ${item[1]}\n`
        })

        try {
            // let token = token
            // axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
            axios.post(`https://api.telegram.org/bot7026747272:AAEoJQk4n09n2fSOXzcGGmhIdQv2WWq4vMA/sendMessage`, {
                // chat_id: chat,
                chat_id: '-1002144853368',
                parse_mode: 'html',
                text: message
            })
            // .then(function (response) {
            //     console.log(response.data)
            // });

            data["subject"] = "Email Avocado";
            data["title"] = "Письмо с сайта калькулятор";

            // let respons = await fetch(`${api}/formcontact_avocado`, {
            let respons = await fetch(`https://evolsar.com/api/formcontact_avocado`, {
                method: 'POST',
                body: JSON.stringify(data),
            });

            let res = await respons;
            let status = res.status;

            return status

        } catch (e) {
            console.log("try: ", e)
        }
    }

    function getData() {
        let q = questions.find(item => item.id == state)

        if (q == undefined) {

            if (stateCal.stateQustion) {
                return (
                    <>
                        <p className='title_form_phone'>Напиши свой номер телефона. Докажи, что ты не <span className='color_phone'>робот</span>:)</p>
                        <div className=''>
                            <form onSubmit={(e) => { e.preventDefault(); setStateCal({ ...stateCal, stateQustion: false }); getSalary(); }}>
                                <fieldset>
                                    <InputMask className="phone_control" name="phone" onChange={(e) => textAnswers['phone'] = e.target.value} placeholder="Номер телефона" mask="+7 (999) 999-99-99" />
                                </fieldset>
                                {/* <input type="text" className='' placeholder="Ваше имя" onChange={(e) => getName(e)} /> */}
                                <Button>
                                    <button type="submit" onClick={(e) => {
                                        try {
                                            // send(e);
                                            ym(96368984,'reachGoal','kviz') ;
                                        } catch (e) {
                                            console.log("Error: ", e)
                                        }
                                    }
                                    }
                                        className='theme_button'>Рассчитать</button>
                                </Button>
                            </form>
                        </div>
                    </>
                )
            } else {
                return (
                    <>
                        {/* <p className=''>{new Intl.NumberFormat('ru-RU').format(Math.trunc(summa))} &#8381;</p> */}
                        <p className='result_calc_test'>Ты будешь зарабатывать <span className='color_result_calc'>{salary.summa} руб</span>  и достигнешь своей цели за <span className='color_result_calc'>{salary.mouth}</span> <span className='mounth_color'>месяц(ев)</span></p>
                    </>
                )
            }

        } else {

            return (
                <>
                    <div className=''
                        dangerouslySetInnerHTML={{ __html: q.title }}
                    />
                    <div className=''>
                        {
                            q.text.map((i, index) =>
                                <div className='option' key={index} data-question={q.question} data-value={i.value} data-coefficient={i.coefficient} onClick={(e) => changeQuestion(e)}>{i.value}</div>

                            )
                        }

                    </div>
                </>
            )

        }
    }

    function renderSlide() {
        let slide = slides.find(item => item.id == stateSlide.value);
        // console.log(slide)
        return <div data-id={slide.id} data-price={slide.data.price} onClick={(e) => getTarget(e)}>
            <img className='image-targer' src={`../images/${slide.img}`} alt={slide.alt} />
            <div className="target_select">
                <div className='select_content'>
                    {stateTarget.state && stateTarget.id == slide.id ? <p className="select">Цель выбрана</p>
                        : <p className="noselect" >Нажми на картинку<br></br>чтобы выбрать цель</p>
                    }
                    <p className="name_select">{slide.data.name}</p>
                </div>
            </div>
        </div>
    }



    return (
        <div className='content_calculator' id='calculator'>
            <div className='slider_calculator'>
                <button id='prev' className='btn' onClick={(e) => changeSlide(e)} >
                    <FontAwesomeIcon icon={faChevronLeft} size="lg" />
                </button>
                {renderSlide()}
                <button id='next' className='btn' onClick={(e) => changeSlide(e)}  >
                    <FontAwesomeIcon icon={faChevronRight} size="lg" />
                    {/* <FontAwesomeIcon icon="fa-chevron-right" /> */}
                </button>
            </div>
            <div className='test_calculator'>
                {getData()}
            </div>

            {modal.state ?
                <ModalWindow visible={modal.state}>
                    <div className='content_modal_calc'>
                        <p>Выберите одну из целей слева</p>
                        <button onClick={closeModal}>OK</button>
                    </div>
                </ModalWindow>
                : <ModalWindow visible={modal.state} />}
        </div>
    )
}