const slidesFoto = [
    {
        "id": 1,
        "img": "studio0.jpg",
        "alt": "Вебкам-студия в Саратове фото офиса",
    },
    {
        "id": 2,
        "img": "studio01.jpg",
        "alt": "Вебкам-студия в Саратове фото офиса",
    },
    {
        "id": 3,
        "img": "studio04.jpg",
        "alt": "Вебкам-студия в Саратове фото офиса 2",
    },
    {
        "id": 4,
        "img": "studio02.jpg",
        "alt": "Вебкам-студия в Саратове фото офиса 2-1",
    },
    {
        "id": 5,
        "img": "studio06.jpg",
        "alt": "Вебкам-студия в Саратове фото офиса 3",
    },
    {
        "id": 6,
        "img": "studio03.jpg",
        "alt": "Вебкам-студия в Саратове фото офиса 4",
    },
]

export default slidesFoto